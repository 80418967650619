import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  dialogContainer: {
    width: '380px',
    height: '530px'
  },

  imageContainer: {
    width: 'auto',
    height: '250px',
    padding: '5px',
    marginTop: '10px'
  },

  header: {
    marginTop: '2%',
    paddingBottom: '0',
    margin:"0px 20px",
  },

  subtext: {
    fontSize: '1em',
    marginTop: '10px',
    color:"#767676"
  },

  headerTime: {
    color: 'black',
    marginTop: '4',
    paddingBottom: '0'
  },

  subtextTime: {
    color: 'black',
    fontSize: '1.05em',
    marginTop: '10px'
  },

  textFieldInner: {
    paddingTop: '9px', 
    paddingBottom: '9px',
  },

  textField: {
    height: '40px',
    width: '310px',
    ['@media (max-width:400px)']: {
      width: "272px"
    },

    ['@media (max-width:368px)']: {
      width: "100%"
    }
  },

  button: {
    margin: 'auto'
  }
}));
