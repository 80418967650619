import {
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import LinkIcon from '@material-ui/icons/Link';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { useState, useEffect } from 'react';
import CustomAlert from '../../shared/components/CustomAlert';
import useStore from '../../shared/store';
import useStyles from '../../../styles/DashboardStyle';
import InviteEmailDialog from './InviteDialog/InviteEmailDialog';
import InvitePhoneDialog from './InviteDialog/InvitePhoneDialog';

const ShareLink = ({ selectedProvider }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [url, setUrl] = useState('');
  const [showCopyAlert, setShowCopy] = useState(false);
  const [showInviteEmailDialog, setShowInviteEmailDialog] = useState(false);
  const [showInvitePhoneDialog, showSetInvitePhoneDialog] = useState(false);

  const handleInvite = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { user, whiteLabel } = useStore();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmailDialogOpen = () => {
    setShowInviteEmailDialog(true);
    setAnchorEl(null);
  };

  const handlePhoneDialogOpen = () => {
    showSetInvitePhoneDialog(true);
    setAnchorEl(null);
  };

  const handleCopyOpen = () => {
    setShowCopy(true);
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    const baseURL = window.location.origin;
    if (!selectedProvider) {
      setUrl('');
    }
    if (
      selectedProvider &&
      user.roles &&
      (user.roles[0] === 'healthOrgAdmin' ||
        user.roles[0] === 'admin' ||
        user.roles[0] === 'oa')
    ) {
      const doctorID = selectedProvider._id;
      setUrl(
        `${baseURL}/${whiteLabel}/?org=${encodeURIComponent(
          whiteLabel
        )}&doctor=${encodeURIComponent(doctorID)}`
      );
    } else if (user.roles && user.roles[0] === 'doctor') {
      setUrl(
        `${baseURL}/${whiteLabel}/?org=${encodeURIComponent(
          whiteLabel
        )}&doctor=${encodeURIComponent(user._id)}`
      );
    }
  }, [user._id, user.roles, whiteLabel, selectedProvider]);

  return (
    <Grid container component={Paper} className={classes.paper}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            <Typography variant="h4" className={classes.shareTitle}>
              Share this link to invite patients into your Virtual Room
            </Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              className={classes.inviteBtn}
              variant="contained"
              endIcon={<ArrowDropDownIcon />}
              onClick={handleInvite}
            >
              Invite
            </Button>
            <Menu
              classes={{ list: classes.menu }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={handleEmailDialogOpen}
              >
                <EmailIcon style={{ color: '#e47029', fontSize: 25 }} />
                &nbsp;&nbsp;Email
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handlePhoneDialogOpen}
              >
                <PhoneIcon style={{ color: '#e47029', fontSize: 25 }} />
                &nbsp;&nbsp;Mobile Phone
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <InviteEmailDialog
          open={showInviteEmailDialog}
          close={() => setShowInviteEmailDialog(false)}
          url={url}
        />
        <InvitePhoneDialog
          open={showInvitePhoneDialog}
          close={() => showSetInvitePhoneDialog(false)}
          url={url}
        />
      </Grid>
      <Grid item xs={12} className={classes.aptCell}>
        <Box className={classes.shareLink}>
          <div className="input-wrapper">
            <LinkIcon className="link-icon" />
            <TextField
              className={classes.shareLinkField}
              value={url}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <Button
            className={classes.copyBtn}
            variant="contained"
            endIcon={<FilterNoneIcon />}
            onClick={handleCopyOpen}
          >
            Copy
          </Button>
        </Box>

        <CustomAlert
          description={'Invite URL Copied'}
          type="success"
          openAlert={showCopyAlert}
          setOpenAlert={setShowCopy}
        />
      </Grid>
    </Grid>
  );
};

export default ShareLink;
