import firebase from 'firebase/app';
import 'firebase/firestore';

export const startSession = (userId, recipientId, doctor, handleSession) => {
    // A patient can only ever have one session. 
    // A doctor can have multiple previous sessions but only one session with the patient from the queue
    const listener = firebase.firestore()
    .collection('peer-connections')
    .where('users', 'array-contains', userId)
    .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
            if (doctor && doc.data().users.includes(recipientId)) {
                handleSession(doc.data());
            }
            if (!doctor) {
                handleSession(doc.data());
            }
        })
    })

    return listener;
}; 

export const doOffer = (toPatientId, userId, callType, callInfo, offer) => {
    firebase.firestore()
    .collection('peer-connections')
    .doc(toPatientId)
    .set({
        users: [toPatientId, userId],
        from: userId,
        type: 'offer',
        callType: callType,
        startTime: new Date(),
        offer: JSON.stringify(offer),
        callInfo: JSON.stringify(callInfo),
        muted: { [userId]: false, [toPatientId]: false },
        quality: {[userId]: 0}
    })
};

export const doAnswer = (userId, answer) => {
    firebase.firestore()
    .collection('peer-connections')
    .doc(userId)
    .update({
        type: 'answer',
        from: userId,
        answer: JSON.stringify(answer)
    })
};

export const doCandidate = (userId, patientId, candidate) => {
    firebase.firestore()
    .collection('peer-connections')
    .doc(patientId)
    .update({
        type: 'candidate',
        from: userId,
        candidate: JSON.stringify(candidate)
    })
};

export const getCallStatus = async (patientId) => {
    const doc = await firebase.firestore()
    .collection('peer-connections')
    .doc(patientId)
    .get()

    return doc.data().type;
};

export const setCallStatus = (patientId, type) => {
    firebase.firestore()
    .collection('peer-connections')
    .doc(patientId)
    .update({
        type: type,    
    })
};

export const setEndCallStatus = (patientId) => {
    firebase.firestore()
    .collection('peer-connections')
    .doc(patientId)
    .update({
        type: "end",
        endTime: new Date()    
    })
}

export const muteMic = async (userId, patientId, mute) => {
    await firebase.firestore()
        .collection('peer-connections')
        .doc(patientId)
        .update({
            [`muted.${userId}`]: mute
        })
}
export const changeQuality = async (userId, patientId, videoRatio) => {
    //update video quality
    await firebase.firestore()
        .collection('peer-connections')
        .doc(patientId)
        .update({
            [`quality.${userId}`]: videoRatio
        })
}
