import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { useState } from 'react';

const TimePicker = ({ onChange, ...props}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(`${date.getHours()}:${date.getMinutes()}`);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker 
          ampm
          fullWidth
          name="time"
          margin="normal"
          inputVariant="outlined"
          placeholder="HH:MM"
          format="HH:mm"
          keyboardIcon={<AccessTimeIcon />}
          invalidDateMessage={null}
          focused={false}
          error={false}
          {...props}
          value={selectedDate}
          onChange={handleDateChange}
        />
    </MuiPickersUtilsProvider>
  )
}

export default withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '5px',
    },
    '& input': {
      borderRadius: 5,
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  },
}))(TimePicker);
