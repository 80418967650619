import React from 'react'
import useStyles from '../../../../styles/VideoContainerStyle/VideoContainer';
import { Grid, Typography } from '@material-ui/core';

export default function VideoContainerHeader({ callInfo }) {
	const classes = useStyles();

	return (
		<>
			<Grid container alignItems="center" noWrap>
			  <Grid item xs={12}>
			    <Typography variant='h3' className={classes.header} style={{color: "#e47129"}}>
						{callInfo && callInfo.patient ? callInfo.patient.name : callInfo.doctor}
					</Typography>
			  </Grid>
			</Grid>
		</>
	)
}
