import { Avatar, Box, Button, Drawer, Typography, useMediaQuery } from '@material-ui/core';
import MeetingHistoryIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/HelpOutline';
import TodayIcon from '@material-ui/icons/Today';
import SettingsIcon from '@material-ui/icons/Tune';
import MailIcon from '@material-ui/icons/MailOutline';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import json2mq from 'json2mq';
import React, { useEffect, useState } from 'react';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import avatar from '../../img/avatar.png';
import useStyles from '../../styles/DrawerStyle';
import { StyledTabs, StyledTab } from '../../styles/DrawerStyle';
import DashboardPage from '../dashboard';
import ConfirmationDialog from '../shared/ConfirmationDialog';

import Help from '../help';
import MeetingHistory from '../meetingHistory';
import Upgrade from '../upgrade';
import StatusUpdate from './components/StatusUpdate';
import { detect } from 'detect-browser';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { createBrowserHistory } from "history";
import adapter from 'webrtc-adapter';
import AppointmentsPage from "../AppointmentsPage";
import SettingPage from "../SettingPage";
import MessagePage from "../MessagePage/message";


import PreCallTest from '../preCallTest/PreCallTest';
import useStore from './store';

const ROUTES_URL = ["/dashboard", "/SettingPage", "/meeting", "/help", "/appointment", "/MessagePage"];
    
const getMenuItems = (role) => {
    const menuItems = [{ name: "Dashboard", url: ROUTES_URL[0], icon: DashboardIcon }];

    if(role === 'oa' || role === 'amin' || role === 'healthOrgAdmin') {
        menuItems.push({ name: "Appointment", url: ROUTES_URL[4], icon: TodayIcon });
    }
    if(role === 'oa' || role === 'amin' || role === 'healthOrgAdmin') {
        menuItems.push({ name: "Setting", url: ROUTES_URL[1], icon: SettingsIcon });
    }
    if (role === 'doctor') {
        menuItems.push({ name: "Meeting History", url: ROUTES_URL[2], icon: MeetingHistoryIcon });
        menuItems.push({ name: "Messages", url: ROUTES_URL[5], icon: MailIcon });
        menuItems.push({ name: "Setting", url: ROUTES_URL[1], icon: SettingsIcon });
    }

    menuItems.push({ name: "Help", url: ROUTES_URL[3], icon: HelpIcon });

    return menuItems;
}

const DrawerMenu = () => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(ROUTES_URL[0]);
    const [showPreCallTest, setShowPreCallTest] = useState();
    const [closeApplicationPopUp, showCloseApplicationPopUp] = useState(false);
    const [sidebar, setSidebar] = useState(true);
    const [browserAbleToRun, setBrowserAbleToRun] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const smallScreenView = useMediaQuery(json2mq({ maxWidth: 1024 }));
    const { setLogin, setOrg, setUser, user, org, login } = useStore();
    const history = createBrowserHistory({ forceRefresh: true });
    const browser = detect();
    const isPatient = !user.roles;
    const logo = org.logo || org.logo_link;

    const handleTabChange = (event, clickedTab) => {
        setCurrentTab(clickedTab);
    };

    const handleMenuClick = () => {
        setSidebar(prev => !prev);
    };

    useEffect(() => {
        if (smallScreenView) {
            setSidebar(false);
        } else {
            setSidebar(true);
        }
    }, [smallScreenView]);

    const handleLogOut = async () => {
        localStorage.removeItem("token");
        try {
            await firebase.auth().signOut();
        } catch(error) {
            console.log('Error: ', error);
        }
        setLogin(false);
        setOrg({});
        setUser({});
        window.location.href = "/";
    };

    const handleCloseApplication = async () => {
        setIsLoading(true);
        window.location.replace(org.website);
        showCloseApplicationPopUp(false);
    };

    const renderMenuItems = (role) => 
        getMenuItems(role).map((item, i) => {
            const IconComponent = item.icon;
            return (
                <StyledTab
                    classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelIcon: classes.labelIcon,
                    }}
                    icon={<IconComponent className={classes.icon}/>}
                    value={item.url}
                    component={Link}
                    to={item.url}
                    label={item.name}
                    key={i}
                />
            );
        });

    useEffect(() => {
        // We would like to prompt the user for microphone and video permissions before they enter the pre-call test or meeting
        // navigator.mediaDevices.getUserMedia({audio: true, video: true})
        // .then(function(stream){
        //     stream.getTracks()[0].stop()
        //     stream.getTracks()[1].stop()
        // })
        // .catch(function(error) {
        //     console.warn(error);
        // });

        if(!adapter.browserDetails.version) {
            // tell the user that their broswer is not support
            setBrowserAbleToRun(false);
            return
        }

        if (adapter.browserDetails.name !== 'safari' &&  browser.os !== 'iOS' && browser.os !== 'Mac OS') {
            navigator.permissions.query({name:"camera"})
                .then(function(permissionObj) {
                    if(permissionObj.state !== "granted") {
                        navigator.mediaDevices.getUserMedia({video: true})
                            .then(function(stream) {
                                stream.getTracks().forEach(function(strack){
                                    strack.stop();
                                });
                            })
                            .catch(function(error) {
                                console.warn(error);
                            });
                    }
                })
                .catch(function(error) {
                    console.log("ask for Camera permission false ", error);
                });

            navigator.permissions.query({name:"microphone"})
                .then(function(permissionObj) {
                    if(permissionObj.state !== "granted") {
                        navigator.mediaDevices.getUserMedia({audio: true})
                            .then(function(stream) {
                                stream.getTracks().forEach(function(strack) {
                                    strack.stop();
                                });
                            });
                    }
                })
                .catch(function(error) {
                    console.log("ask for Camera permission false ", error);
                });
        } else {
            console.log("navigator.mediaDevices")
            console.log(navigator.mediaDevices)
            console.log("navigator.mediaDevices === undefine")
            console.log(navigator.mediaDevices === undefined)
            console.log("navigator.getUserMedia")
            console.log(navigator.getUserMedia)
            console.log("navigator.mediaDevices.getUserMedia")
        }
    }, []);

    useEffect(() => {
        if(ROUTES_URL.includes(history.location.pathname)) {
            setCurrentTab(history.location.pathname);
        }
    }, [history.location])

    return (
        !browserAbleToRun ?
            <StatusUpdate
                header="Browser Not Supporting"
                message="Please update your browser or use another browser to use this app"
                closeAction={() => {}}  // do nothing
            />
            :
            <div className={classes.root}>
                <Router history={history}>
                    {/* DrawerMenu on the left */}
                    {sidebar &&
                        <Drawer
                            className={classes.drawer}
                            classes={{ paper: classes.drawerPaper }}
                            open={sidebar}
                            onClose={handleMenuClick}
                            variant={smallScreenView ? "temporary" : "permanent"}
                            anchor="left"
                        >
                            {/* Logo */}
                            <Box
                                className={classes.logo}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {logo && <img src={logo} alt="clinic-logo" width="170" loading="lazy" /> }
                            </Box>

                            {/* Tabs menu */}
                            <StyledTabs
                                orientation="vertical"
                                variant="scrollable"
                                value={currentTab}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs"
                                className={classes.tabs}
                            >
                                {/* Account */}
                                {login &&
                                    <StyledTab style={{ display: 'flex' }}
                                        className={classes.account}
                                        display='flex'
                                        component={Link}
                                        to={"/SettingPage"}
                                        key={0}
                                        icon={<Avatar src={avatar} className={classes.avatar} />}
                                        label={
                                            <Typography className={classes.user}>
                                                {user.contact_name ? user.contact_name
                                                    : user.roles && user.roles[0] === 'admin' ? 'Admin'
                                                        : user.roles && user.roles[0] === 'healthOrgAdmin' ? 'HAdmin'
                                                            : user.roles && user.roles[0] === 'oa' ? 'MOA'
                                                                : user.roles && user.roles[0] === 'doctor' ? 'Doctor'
                                                                    : 'User'
                                                }
                                            </Typography>
                                        }
                                    />
                                }
                                {renderMenuItems(user.roles && user.roles[0])}
                            </StyledTabs>
                            {/* Pre-call Test */}
                            <Box display='flex' flexGrow={1} alignItems='flex-end' justifyContent='center'>
                                <Button
                                    onClick={() => setShowPreCallTest(true)}
                                    style={{ border: "1px solid #e47029", color: "#e47029" }}
                                    className={classes.preCallBtn}
                                    variant='outlined'
                                    size='large'
                                >
                                    Pre-Call Test
                                </Button>
                            </Box>
                            {/* Sign out */}
                            <Box className={classes.signOut}>
                                {login &&
                                    <Button
                                        classes={{startIcon: classes.signOutBtnIcon}}
                                        className={classes.signOutBtn}
                                        startIcon={<ExitToAppIcon/>}
                                        size='large'
                                        onClick={handleLogOut}
                                    >
                                        Sign Out
                                    </Button>
                                }
                                {isPatient && 
                                    <Button
                                        classes={{startIcon: classes.signOutBtnIcon}}
                                        className={classes.signOutBtn}
                                        startIcon={<ExitToAppIcon/>}
                                        size='large'
                                        onClick={() => showCloseApplicationPopUp(true)}
                                    >
                                        Exit
                                    </Button>
                                }
                            </Box>
                            {/* Copyright */}
                            <Box className={classes.copyright}>
                                <Typography 
                                    className={classes.copyRightText} 
                                    color='textSecondary' 
                                    align='center'
                                >
                                    &copy; {new Date().getFullYear()} Porton Health
                                </Typography>
                            </Box>
                        </Drawer>
                    }
                    {/* Main Content on the right */}
                    <Box className={classes.content}>
                        <Switch>
                            <Route
                                path='/dashboard'
                                render={(props) => (
                                    <DashboardPage
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />
                            <Route
                                path='/meeting'
                                render={(props) => (
                                    <MeetingHistory
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />
                            <Route
                                path='/SettingPage'
                                render={(props) => (
                                    <SettingPage
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />
                            <Route
                                path='/appointment'
                                render={(props) => (
                                    <AppointmentsPage
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />

                            <Route 
                                path='/help'
                                render={(props) => (
                                    <Help
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )} 
                            />

                            <Route
                                path='/MessagePage'
                                render={(props) => (
                                    <MessagePage
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />
                            <Route 
                               path='/upgrade'
                                render={(props) => (
                                    <Upgrade
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                               )}
                            />
                            <Route
                                path='/'
                                render={(props) => (
                                    <DashboardPage 
                                        {...props}
                                        smallScreenView={smallScreenView}
                                        handleMenuClick={handleMenuClick}
                                    />
                                )}
                            />
                        </Switch>
                    </Box>
                </Router>
                {/* Render the pre-call test */}
                {showPreCallTest && <PreCallTest showPreCallTest={showPreCallTest} setShowPreCallTest={setShowPreCallTest}/>}

                {/* Render the close Application PopUp */}
                {closeApplicationPopUp && 
                    <ConfirmationDialog 
                        open={closeApplicationPopUp}
                        icon={ContactSupportIcon}
                        isLoading={isLoading}
                        text="Are you sure you want to leave the application?"
                        textStyle={{ fontWeight: "bold", color: "#444444" }}
                        onClose={() => showCloseApplicationPopUp(false)}
                        onConfirm={handleCloseApplication}
                    />
                }
            </div>
    );
};

export default DrawerMenu;
