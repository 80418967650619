import { ThemeProvider, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { useState } from 'react';
import moment from 'moment';
import CustomAlert from '../../../shared/components/CustomAlert';
import useStore from '../../../shared/store';
import { api } from '../../../shared/utilities/api';
import ApiConfigs from '../../../shared/utilities/apiConfigs';
import { customInputTheme } from '../../../../styles/CustomThemes';
import useStyles from '../../../../styles/DashboardStyle';
import BootstrapInput from '../../../../styles/DialogStyle/components/BootstrapInput';
import ConfirmationDialog from '../../../shared/ConfirmationDialog';
import TimePicker from '../../../shared/TimePicker';
import { dateFormatter, timeFormatter } from './utils';

const InvitePhoneDialog = ({ open, close, url }) => {
  const classes = useStyles();
  const { org, user } = useStore();
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showInvitationSendPopup, setShowInvitationSendPopup] = useState(false);
  const [alertProps, setAlertProps] = useState({
    description: '',
    type: '',
  });
  const [time, setTime] = useState({
    date: '',
    time: '',
  });
  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [patientName, setPatientName] = useState('');
  const [isValidPatientName, setIsValidPatientName] = useState(true);

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleChangeName = (e) => {
    setPatientName(e.target.value);
  };
  const handleChangeTime = (e) => {
    setTime({ ...time, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setPhone('');
    setPatientName('');
    setTime({
      date: '',
      time: '',
    });
    close();
    setIsSending(false);
  };

  const handleCloseInvitationSendPopup = () =>
    setShowInvitationSendPopup(false);

  const getNumIssues = () => {
    let issues = 0;

    let isValidName = /^[a-zA-Z ]{2,30}$/.test(patientName);
    setIsValidPatientName(isValidName);

    const isValidPhone =
      /^[]?[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(phone);
    setIsPhoneValid(isValidPhone);

    const dateTimeObj = moment(time.date + ' ' + time.time);

    let isValidDate =
      /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/.test(time.date) &&
      moment().diff(dateTimeObj, 'day') <= 0;
    if (!isValidDate) {
      setDateError('Enter Valid Date ');
      issues++;
    } else {
      setDateError('');
    }

    let isValidTime =
      /^(?!\s*$).+/.test(time.time) && moment(dateTimeObj, 'HH:mm').isValid();
    if (!isValidTime) {
      setTimeError('Enter valid time');
      issues++;
    } else {
      setTimeError('');
    }
    if (!isValidPhone || !isValidName) {
      issues++;
    }
    return issues;
  };

  const handleSendPhoneInvitation = async () => {
    console.log('            IN HERE');

    console.log(org);
    let numIssues = getNumIssues();
    if (numIssues > 0) return;

    setIsSending(true);

    const formattedTime = timeFormatter(time.time, 30);

    if (!url) {
      setAlertProps({
        description:
          'Provider not selected. Please pick a provider to continue',
        type: 'error',
      });
      setOpenAlert(true);
      close();
      setIsSending(false);

      return;
    }

    let databaseSmtpSetting =
      org.communicationSetting && org.communicationSetting.smtp_setting;
    let notificationSetting = org.communicationSetting.notification_setting.sms;
    let smtpSetting = null;

    if (databaseSmtpSetting && notificationSetting) {
      smtpSetting = {
        host: databaseSmtpSetting.smtpserver,
        port: databaseSmtpSetting.smtpport,
        secure: true,
        auth: {
          user: databaseSmtpSetting.smtpuser,
          pass: databaseSmtpSetting.smtppassword,
        },
      };
      if (
        !smtpSetting.host ||
        !smtpSetting.port ||
        !smtpSetting.auth.user ||
        !smtpSetting.auth.pass
      )
        smtpSetting = null;
    }

    const res = await api(
      ApiConfigs.baseOption('post', 'appointments/shareLinkSms', {
        data: {
          patientPhoneNumber: phone,
          patientName,
          org: org,
          link: url,
          doctor: user.contact_name,
          date: dateFormatter(time.date),
          start: formattedTime.open,
          end: formattedTime.close,
        },
      })
    );

    if (res && res.status === 200) {
      setShowInvitationSendPopup(true);
    } else {
      setAlertProps({
        description: `Failed to send invitation.`,
        type: 'error',
      });
      setOpenAlert(true);
    }
    handleClose();
  };

  const handleChangeSelectedTime = (newTime) =>
    setTime({ ...time, time: newTime });

  return (
    <>
      <Dialog open={open}>
        <DialogTitle
          classes={{ root: classes.inviteDialogTitle }}
          disableTypography
        >
          <Typography variant="h6">Invite via Mobile</Typography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid container spacing={3}>
            {/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? (
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.shareTitleStyle}
                  shrink
                  htmlFor="date-input"
                >
                  Date
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  name="date"
                  value={time.date}
                  inputProps={{
                    min: moment().format('YYYY-MM-DD'),
                  }}
                  id="date-input"
                  placeholder={'YYYY-MM-DD'}
                  onChange={handleChangeTime}
                />
                <FormHelperText error>{dateError}</FormHelperText>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.shareTitleStyle}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Date
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  type="date"
                  name="date"
                  value={time.date}
                  inputProps={{
                    min: moment().format('YYYY-MM-DD'),
                  }}
                  onChange={handleChangeTime}
                  id="bootstrap-input"
                />
                <FormHelperText error>{dateError}</FormHelperText>
              </Grid>
            )}

            {/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.shareTitleStyle}
                  shrink
                  htmlFor="time-input"
                >
                  Time
                </InputLabel>

                <TimePicker onChange={handleChangeSelectedTime} />
                <FormHelperText error>{timeError}</FormHelperText>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.shareTitleStyle}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Time
                </InputLabel>
                <BootstrapInput
                  onChange={handleChangeTime}
                  name="time"
                  value={time.time}
                  fullWidth
                  type="time"
                  id="bootstrap-input"
                />
                <FormHelperText error>{timeError}</FormHelperText>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel
                className={classes.shareTitleStyle}
                shrink
                htmlFor="bootstrap-input"
              >
                Patient Name
              </InputLabel>
              <ThemeProvider theme={customInputTheme}>
                <TextField
                  error={!isValidPatientName}
                  helperText={
                    !isValidPatientName &&
                    'Sorry, please enter a valid name. Names must be at least two characters long'
                  }
                  autoFocus
                  margin="dense"
                  type="patientName"
                  placeholder="Patient Name"
                  variant="outlined"
                  fullWidth
                  value={patientName}
                  onChange={handleChangeName}
                />
              </ThemeProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                className={classes.shareTitleStyle}
                shrink
                htmlFor="bootstrap-input"
              >
                Patient Phone
              </InputLabel>
              <ThemeProvider theme={customInputTheme}>
                <TextField
                  error={!isPhoneValid}
                  helperText={
                    !isPhoneValid && 'Sorry, please Enter a valid Phone number.'
                  }
                  autoFocus
                  margin="dense"
                  type="phone"
                  placeholder="Patient Phone"
                  variant="outlined"
                  value={phone}
                  fullWidth
                  onChange={handleChangePhone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon style={{ color: '#BDBDBD' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {isSending ? (
            <CircularProgress style={{ margin: '0 auto 5px' }} />
          ) : (
            <div className={classes.inviteDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.inviteDialogActionBtn}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSendPhoneInvitation}
                className={classes.inviteDialogActionBtn}
              >
                Send
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <CustomAlert
        description={alertProps.description}
        type={alertProps.type}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
      />

      <ConfirmationDialog
        open={showInvitationSendPopup}
        title="Your invite has been sent"
        text="An invitation text message has been sent to the patient"
        closeButtonTitle="OK"
        onClose={handleCloseInvitationSendPopup}
      />
    </>
  );
};

export default InvitePhoneDialog;
