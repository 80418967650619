/*
 * @Author: Tyler Bowman
 * @Date: 2021-01-22 10:49:56
 * @LastEditors: William Lee
 * @LastEditTime: 2021-06-28 10:23:03
 * @Description: Style Reformating
 */
import { makeStyles } from '@material-ui/core/styles';

//Warning: If any of these settings are overwritten by a style of the same name in one file, 
//ie. if reminderapp has a head with different attributes in their makestyles function
//it may apply that overwrite globally, changing the styles for all other files using this file
export default makeStyles((theme) => ({
    root: {
        border: "2px solid rgba(255,255,255,0)",
        borderRadius: "8px"
    },
    rootExpanded: {
        border: `2px solid ${theme.palette.primary.orange}`,
        borderRadius: "8px",
        padding: "3px",
    },
    disabled: {
        backgroundColor: "#EAEAEA"
    },
    paperRoot: {
        padding: '2px 4px',
        display: 'flex',
        width: 600,
        background:'#EAEAEA',
        marginLeft:'25%'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        background:'#EAEAEA',
    },

    verticalDivider: {
        height: 28,
        margin: 4,
    },
    head: {
        display: 'flex',
    },
    title: {
        paddingTop: "0rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "3rem"
    },
    avatar: {
        width: 80,
        height: 80,
        marginRight: 15,
    },

    avatarLarge: {
        width: "25%",
        height: "25%",
        margin : 20
    },

    labelContentTitle: {
        // paddingTop: "0.5rem",
        fontSize: "1.2rem",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin: 16,
    },

    plainSwitch:{
        root: {
            color: theme.palette.primary.orange,
        },
        margin: 16,
    },

    appBox: {
        margin: "2rem 0",
    },
    container: {
        // padding: "0.0rem 1rem 1rem 1rem"
    },
    inputAndCopyButtonContainer: {
        display: "flex",
        padding: "0.0rem 1rem 1rem 1rem"
    },
    copyLinkButtonContainer: {
        display: "flex",
        marginRight:'-38%',
        marginTop:'2.5%',
    },
    copyButton: {
        backgroundColor: "#003FBA",
        color: "white",
        fontSize: "1rem",

    },
    labelWithEdit: {
        display: "flex",
        justifyContent: "space-between",
        '&:hover': {
            cursor: 'pointer',
        },
    },
    discriptionWithEidt:{
        display: "flex",
        justifyContent: "space-between",
        margin:"1rem 1.5rem 0 2rem",
    },
    fingerprintIcon: {
        fontSize:'700%',
        color:'#767676',
    },
    fingerprintIconDiv: {
        textAlign:'center',
        marginTop:'5%',
        marginBottom:'5%',
    },
    irisScanDiv: {
        textAlign:'center',
        borderRadius:'10px',
        marginTop:'5%',
        position:'relative',
    },
    webcamBorder: {
        'webkit-border-radius':'10px',
        borderRadius:'10px',
    },
    irisScanOverlay: {
        borderRadius:'20px',
        position:'absolute',
        width:'100%',
        top:'0',
        right:'0',
        bottom:'0',
        left:'0',
    },
    cancelIcon: {
        color:'#003FBA',
        width: '15%',
        height: '15%',
    },
    labelLarge: {
        // paddingTop: "0.5rem",
        fontSize: "32px",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0"
    },
    labelExpanded: {
        color: theme.palette.primary.orange,
        fontSize: "1.2rem",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    labelCollapsed: {
        color: "black",
        fontSize: "1.2rem",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    edit: {
        fontSize: "0.8rem",
        color: "#003FBA",
        fontWeight: 600,
        marginRight: "0.5rem",
        cursor: "pointer"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    paperComponment: {
        padding: "1rem 1rem"
    },
    papersubComponment: {
        padding: "0.5rem 1rem"
    },
    discription:{
        fontSize:"0.8rem",
        color:"#767676"

    },
    labelBlock: {
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        padding: "6px",
        paddingRight: "8px",
        paddingLeft: "12px",
    },
    labelSmallIris: {
        fontSize: "1rem",
        color: "#003FBA",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
    },
    labelSmall: {
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    labelSmallEnabled: {
        fontSize: "1rem",
        color: "#003FBA",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    labelMedSmall: {
        fontSize: "1rem",
        color: "#767676",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        marginBottom: "15px",
    },
    medRecordInline: {
        display:'inline',
    },
    medRecordCard: {
        width:'100%',
        marginBottom:'2.5%',
    },
    buttonUpload: {
        width: "100%",
        backgroundColor: "#fff",
        border: "8px #000",
        justifyContent: "start",
        shadow: "none"
    },
    disabledSettings: {
        pointerEvents: "none",
    },
    disabledBackground: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    saveChangesBtn: {
        marginLeft: "25px",
    },
    editToggleBtn: {
        marginTop: "20px",
        marginRight: "25px",
    },
    loadingCircle: {
        margin: "10px",
        color: "#1766b3",
        marginRight:"50px"
    },
    labelWithEditBottomSpacing: {
        marginBottom: "20px"
    },
    labelWithEditWidth: {
        width: "100%"
    },
    textBox: {
        width: "100%",
        marginTop: "2px",
        marginBottom: "2px",
        padding:"6px 1px 2px;"
    },
    textCounter: {
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "right",
        color: "#767676",
        marginRight: "4px",
    },
    labelText: {
        fontSize: "15px",
        lineHeight: "14px",
        color: "#343434",
        marginTop: "8px",
        marginLeft: "7px",
    },
    labelTemplateText: {
        fontSize: "12px",
        lineHeight: "14px",
        color: "#343434",
        marginTop: "8px",
        marginBottom: "8px",
        marginLeft: "4px",
    },
    optionList: {
        letterSpacing: "0.1px",
        paddingLeft: "16px",
        paddingTop: "15px",
        paddingBottom: "14px",
    },
    textList: {
        letterSpacing: "0.1px",
        paddingLeft: "7px",
        paddingTop: "3px",
        paddingBottom: "4px",
    },
    listDefault: {
        color: "#767676",
    },
    editUrlBox: {
        padding: "12px 24px"
    },
    labelSmallerSpacing: {
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        marginTop: "8px",
        marginBottom: "8px",
    },
    smallContainer: {
        marginTop: "16px"
    },
    subButton: {
        marginTop: "7px",
    },
    editportalbutton: {
        fontWeight:'500',
    },
    statusText: {
        display: 'inline',
        color: '#767676',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '18.75px',
    },
    formControl: {
        width: '100%',
        boxSizing: "border-box",
        borderRadius: "4px",
        border: '1px solid #c7c7c7',
        padding: '3px',
    },
    dividerBorder: {
        borderRight: "solid 1px #0000001f",
    },
    operationsDividerBorder: {
        borderRight: "solid 1px #0000001f",
        paddingBottom:'11.25%',
    },
    copyUrlButton: {
        position: 'absolute',
        right: 10,
        top: 7,
        borderRadius: 5,
        backgroundColor: '#003FBA',
        color: '#fff'
    },
    disabledCopyUrlButton: {
        position: 'absolute',
        right: 10,
        top: 7,
        borderRadius: 5,
        backgroundColor: '#767676',
        color: '#fff'
    },
    urlTextField: {
        padding: 10,
        borderRadius: 5,
        backgroundColor:'#EAEAEA',
    },
    urlTextBox: {
        position: 'relative'
    },
    urlTextDisplay: {
        wordWrap:"break-word"
    },
    saveBtnGroup: {
        padding: '20px 25px'
    },
    section: {
        padding: 16
    },
    menu: {
        padding: 0
    },
    menuItem: {
        minWidth: 90,
        height: 48
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 24px'
    },
    dialogMedActions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '24px',
        paddingLeft: '24px',
        marginTop:'-5px',
        marginBottom: '10px',
    },
    confirmPassword: {
        marginTop: 20
    },
    generateTypography: {
        background:'radial-gradient(196.67% 240.67% at -54.22% -111.25%, #FFFFFF 0, #003FBA 100%)',
        color:'#fff',
    },
    containerSpacing: {
        paddingLeft:"16px",
        paddingRight:"16px",
        paddingBottom:"10px"
    },
    appointmentBlockCodeColor:{
        textAlign:"left",
        height:"2rem",
        width:"3.5rem",
        marginRight:"0.5rem",
        borderRadius:"10%",
        display:"flex"
    },
    cancelIconButtonColor:{
        marginTop: "7px",
        height:"1rem",
        width:"1rem",
    },
    blockCodeGrid: {
        paddingLeft: "1px",
        paddingBottom: "1rem"
    },
    appointmentModeSampleColor:{
        textAlign:"left",
        height:"1rem",
        width:"1rem",
        marginRight:"1rem",
        borderRadius:"10%",
    },
    appointmentModeSampleBox:{
        textAlign:"left",
        padding:"0.4rem 1rem 0.4rem 1rem",
        display:"flex",
        alignItems:"center"
    },
    dropDownSelectSpacing:{
        marginLeft:"auto",
        marginRight:"20px"
    },
    appointmentTypeButton:{
        float: 'right',
        paddingRight: '0px',
        whiteSpace: 'nowrap'
    },
    labelWithAdd: {
        padding:"6px"
    },
    checkInAppCopyButton:{
        width: '15%',
        backgroundColor: '#003FBA',
        color: '#fff'
    },
    checkInAppCopyButtonDisabled:{
        width: '15%',
        backgroundColor: '#767676',
        color: '#fff'
    },
    iconButtonSpacing: {
        padding:"0px",
        paddingBottom: "4px"
    },
    iconButtonSize: {
        width: "16px",
        height: "16px",
    },
    dropdownAppointmentSpacing: {
        marginLeft:"2px"
    },
    checkinAppSubHeader:{
        padding: '0 16px'
    },
    boxContainer: {
        display: 'flex',
        float: 'right'
    },
    meatBallIcon: {
        marginLeft: '5px'
    },
    appointmentReasonSpacing: {
        marginBottom:'2%'
    },
    appointmentReasonFormating: {
        margin: '0',
        paddingTop: '9px'
    },
    appointmentReasonAddButton: {
        float: 'right',
        paddingRight: '0px',
        whiteSpace: 'nowrap'
    },
    borderExpanded: {
        border: "2px solid #003FBA",
        borderRadius: "10px"
    },
    borderClosed: {
        border: "2px solid rgba(255,255,255,0)",
        borderRadius: "10px"
    },
    bookingBox: {
        width: "100%",
        alignItems: 'center'
    },
    appointmentReasonListName: {
        marginBottom: '2px',
        marginTop: '4px',
        marginLeft: "3px"
    },
    appointmentReasonListDescription: {
        marginLeft: "3px"
    },
    appointmentReasonAddForm: {
        marginTop: '20px'
    },
    appointmentReasonFormTextSpacing: {
        marginBottom: '-10px'
    },
    appointmentReasonFormText:{
        paddingBottom: '8px'
    },
    appointmentReasonCancel: {
        fontSize: '16px'
    },
    appointmentReasonConfirm: {
        float: 'right',
        fontSize: '16px'
    },
}));
