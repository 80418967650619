import { Grid, Typography } from '@material-ui/core';
import { detect } from 'detect-browser';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import adapter from 'webrtc-adapter';
import useStyles from '../../styles/DashboardStyle';
import useStore from '../shared/store';
import { updatePermissions } from "../shared/utilities/firebaseUtil";
import Chats from './components/Chats';
import PatientCheckIn from './components/PatientCheckIn';
import PatientGreeting from './components/PatientGreeting';
import PatientQueue from './components/PatientQueue';
import ProviderMenu from './components/ProviderMenu';
import ShareLink from './components/ShareLink';
import VideoContainer from './components/VideoCall/VideoContainer';
import AppointmentsTodayNew from "./components/AppointmentsTodayNew"
import AnnouncementDisplay from './components/AnnouncementDisplay';
import HumburgerButton from "../shared/HumburgerButton";

const Dashboard = ({ smallScreenView, handleMenuClick }) => {

    const [patientCheckedIn, setPatientCheckedIn] = useState(false);
    const [patientPosition, setPatientPosition] = useState(0);
    const [patientJoinCall, setPatientJoinCall] = useState(false);
    const [patient, setPatient] = useState(null);
    const [allUserChats, setAllUserChats] = useState({});
    const [startCall, setStartCall] = useState(false);
    const [callReady, setCallReady] = useState(false);
    const [callInfo, setCallInfo] = useState({ patient: null, doctor: null, type: null })
    const [expanded, setExpanded] = useState({});
    const [selectedProvider, setSelectedProvider] = useState();
    const [chats, setChats] = useState({});
    const [disablePatientChat, setDisablePatientChat] = useState(false);

    const classes = useStyles();
    const { user, envVars } = useStore();
    const browser = detect();
    const pingInterval = envVars.appPing;
    let patientActivePing = useRef(null);

    const isPatient = !user.roles;

    useEffect(() => {
        const onUnload = (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            if(patient) {
                firebase
                    .firestore()
                    .collection("patient-queue")
                    .doc(patient.id)
                    .delete()
                    .finally(() => {
                        window.close();
                    })
                return false;
            }
        }
        window.addEventListener("beforeunload", onUnload );
        return () => window.removeEventListener('beforeunload', onUnload)

    }, [patient])

    useEffect(() => {
        const session = localStorage.getItem('session');
        if (session) setPatient(JSON.parse(session));
    }, [patientCheckedIn]);

    const checkState = (permission) => permission === 'granted';

    // Check permissions. If any device is disabled that is required for the doctor to start the call, it should not start.
    useEffect(() => {
        // Safari does not currently support Navigator.permissions
        console.log("dashBard index")
        console.log("browser")
        console.log(browser)
        console.log("adapter")
        console.log(adapter)
        console.log("browser detail")
        console.log(adapter.browserDetails)
        if(patient) {
            if (browser.name !== 'safari' && 
                adapter.browserDetails.version &&
                browser.os !== 'iOS' &&
                 browser.os !== 'Mac OS') {
                navigator.permissions.query({name: 'microphone'}).then(function(permission) {
                    updatePermissions('audio', checkState(permission.state), patient);
                    permission.onchange = function() { 
                        updatePermissions('audio', checkState(permission.state), patient);
                    };
                });
            
                navigator.permissions.query({name: 'camera'}).then(function(permission) { 
                    updatePermissions('video', checkState(permission.state), patient);
                    permission.onchange = function() {  
                        updatePermissions('video', checkState(permission.state), patient);
                    };
                });
            }
            else {
                try{
                    navigator.mediaDevices.getUserMedia({audio: true, video: true})
                    .then(function(stream){
                        updatePermissions("audio", true, patient)
                        updatePermissions("video", true, patient)
                        stream.getTracks()[0].stop()
                        stream.getTracks()[1].stop()
                    })
                    .catch(function(error) {
                        console.warn(error);
                    });
                }
                catch(error) {
                    console.log("can not get mediaDevices")
                    console.log(error)     
                }
            }
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient]);

   

    // Periodically ping the firebase server to let it know we are still active and should not be removed
    useEffect(() => {
        if (patient && pingInterval) {
            patientActivePing.current = setInterval(() => {
                let currentDate = new Date();                
                firebase.firestore()
                .collection('patient-queue')
                .doc(patient.id)
                .update({
                    lastUpdated: firebase.firestore.Timestamp.fromDate(currentDate)
                }).then(() => {
                    console.log("Client last active time updated.");
                    // Update local session 
                    const session = JSON.parse(localStorage.getItem('session'));
                    session.lastUpdated = moment(currentDate).format();
                    localStorage.setItem('session', JSON.stringify(session));
                })
                .catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
                console.log("Ping")
            }, pingInterval);
        }

        return () => {
            clearInterval(patientActivePing.current);
        }
    }, [patient, pingInterval]);

    return (
        <>
        { startCall ? 
        <VideoContainer 
            callInfo={callInfo}
            setStartCall={setStartCall}
            setDisablePatientChat={setDisablePatientChat}
        /> :
         <>
        
         <Grid container className={classes.root} spacing={4} style={{paddingTop: '0px'}}>
           <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />

             <Grid item xs={12} style={smallScreenView ? {paddingTop: '0px'} : {paddingTop: '30px'}}>
                 {user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') ?
                     <Grid container spacing={3}>
                         <Grid item xs={6}>
                             <Typography variant='h2' style={{color:"black"}}>
                                 Hello,
                                 <span className={classes.userName}>
                                 {user.contact_name ? user.contact_name 
                                 : user.roles && user.roles[0] === 'admin' ? 'Admin' 
                                 : user.roles && user.roles[0] === 'healthOrgAdmin' ? 'HAdmin' 
                                 : user.roles && user.roles[0] === 'oa' ? 'MOA' 
                                 : user.roles && user.roles[0] === 'doctor' ? 'Doctor'
                                 : ''}
                                 </span>!
                             </Typography>
                         </Grid>
                        {(user.roles[0] === 'oa' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') &&
                            <Grid item xs={12} container justifyContent='flex-end' style={{display:"flex", flex:"1"}}>
                                <ProviderMenu setSelectedProvider={setSelectedProvider}
                                            selectedProvider={selectedProvider}
                                            className={classes.inviteBtn}/>
                            </Grid>
                        }
                         <Grid item xs={12} >
                             <ShareLink selectedProvider={selectedProvider}/>
                         </Grid>
                     </Grid>
                     :
                     <PatientCheckIn setPatientCheckedIn={setPatientCheckedIn}/>
                 }
             </Grid>
             <Grid item xs={12}>
                 <Grid container spacing={3}>
                     
                        {(user?.roles?.length > 0  && (user.roles[0] === 'oa' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin'))  && 
                            <Grid item xs>
                             <AppointmentsTodayNew selectedProvider={selectedProvider} />
                         </Grid>  
                            }
                     { patientCheckedIn &&
                     <Grid item xs={12}>
                         <PatientGreeting patientPosition={patientPosition} 
                                          setPatientJoinCall={setPatientJoinCall} 
                                          callReady={callReady}
                                          callInfo={callInfo}
                                          user = {user}/>
                     </Grid>
                     }

                    {isPatient && <AnnouncementDisplay />}

                    <Grid item xs={12}>
                        <PatientQueue 
                            setChats={setChats}
                            setExpanded={setExpanded}
                            patient={patient}
                            setPatientPosition={setPatientPosition}
                            patientJoinCall={patientJoinCall}
                            allUserChats={allUserChats}
                            setStartCall={setStartCall} 
                            setCallReady={setCallReady}
                            setCallInfo={setCallInfo}
                            selectedProvider={selectedProvider}
                        />
                    </Grid>
                 </Grid>
             </Grid>
         </Grid >
         
         <Chats
             chats={chats}
             expanded={expanded}
             setChats={setChats} 
             setExpanded={setExpanded} 
             patientCheckedIn={patientCheckedIn}
             allUserChats={allUserChats}
             setAllUserChats={setAllUserChats}
             disablePatientChat={disablePatientChat}
         />
     
        </>
        
        }
        </>
    );
}

export default Dashboard;