import React, { useState, useEffect, useRef } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from '../../../styles/DashboardStyle';
import Chat from './Chat';
import jwt_decode from "jwt-decode";
import firebase from 'firebase/app';
import 'firebase/firestore';

export default function Chats({ chats, expanded, setChats, setExpanded, patientCheckedIn, allUserChats, setAllUserChats, disablePatientChat }) {

  const [chatsPerPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientMessageCount, setPatientMessageCount] = useState({});
  const user = useRef(false);
  const prevExpanded = useRef({});
  const prevOpened = useRef(null);

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded((prev) => ({...prev, [panel]: isExpanded}));
  };

  const closeChat = (id) => {
      const { [id]: temp, ...rest } = chats;
      setChats(rest);
      setCurrentPage(1);
  };

  const classes = useStyles();
  const indexOfLastChat = currentPage * chatsPerPage;
  const indexOfFirstChat = indexOfLastChat - chatsPerPage;
  const currentChats = Object.keys(chats).slice(indexOfFirstChat, indexOfLastChat);

  // Get all our chats that include our ID
  useEffect(() => {
    const token = localStorage.getItem('token');
    let patient;
    if (token) {
      // If user
      const decoded = jwt_decode(token)
      user.current = {userId: decoded._id, patient: false};
    } else {
      // If patient
      patient = localStorage.getItem('session');
      if (patient) user.current = {userId: JSON.parse(patient).id, patient: true};
    }

    let unsubscribe;
    if (user.current) {
      unsubscribe = firebase.firestore()
      .collection('chats')
      .where("users", "array-contains", user.current.userId)
      .onSnapshot((snapshot) => {
        let tempAllUserChats = {};
        snapshot.forEach((doc) => {

          const recipient = doc.id.split(":").filter(ids => { return user.current.userId !== ids })
          tempAllUserChats = {...tempAllUserChats, [recipient]: doc.data()};
          
      });
      setAllUserChats(tempAllUserChats)
      }, (error) => {
        console.log(error)
      });
    }

    if (unsubscribe) return () => unsubscribe();
    // eslint-disable-next-line
  }, [patientCheckedIn])

  useEffect(() => {
    // If patient, their chats need to be added without clicking the chat icon button
    if(user.current.patient && Object.keys(allUserChats).length && !disablePatientChat) {
      Object.keys(allUserChats).forEach(recipientId => {
        setChats((prev) => ({ ...prev, [recipientId]: {name: allUserChats[recipientId].chatUsername}}));
        // Only expand the patients chat when when it does not exist
        if (!chats[recipientId]) {
          setExpanded((prev) => ({ ...prev, [recipientId]: true }));
          patientMessageCount[recipientId] = allUserChats[recipientId].messages.length;
        } else {
          // If the patient has recieved a new message, expand the chat window
          if (allUserChats[recipientId].messages.length > patientMessageCount[recipientId]) {
            setPatientMessageCount({...patientMessageCount, [recipientId]: allUserChats[recipientId].messages.length});
            setExpanded((prev) => ({ ...prev, [recipientId]: true }));
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [allUserChats]);

  const updateChat = async (patientId, viewing) => {
    if (typeof viewing === 'undefined') return; 
    firebase.firestore()
    .collection('chats')
    .doc(`${user.current.userId}:${patientId}`)
    .update({
      chatWindow: {
        time: firebase.firestore.Timestamp.fromDate(new Date()),
        viewing: viewing
      }
    });
  }

  useEffect(() => {
    if (!user.current.patient) {
      
      if (!Object.keys(prevExpanded.current).length) prevExpanded.current = expanded;
      if (!prevOpened.current) prevOpened.current = chats;
      let viewing;
      let chatMinMaxChange = false;
      // If chat is open, update the lastViewed with the latest messageId
      for (let patientId of Object.keys(expanded)) {
        // There has been a change
        if (expanded[patientId] !== prevExpanded.current[patientId]) {
          // If the chat has gone from expanded to minimized
          if (!expanded[patientId] && prevExpanded.current[patientId]) viewing = false;
          if (expanded[patientId] && !prevExpanded.current[patientId]) viewing = true;

          updateChat(patientId, viewing)
          .then(() => {
            prevExpanded.current = expanded;
          });
          chatMinMaxChange = true;
        }
      }

      if (!chatMinMaxChange) {
        if (Object.keys(chats).length > Object.keys(prevOpened.current).length) {
          for (let patientId of Object.keys(chats)) {
            if (chats[patientId] && !prevOpened.current[patientId]) viewing = true;
            updateChat(patientId, viewing)
            .then(() => {
              prevOpened.current = chats;
            });
          }
        } else {
          for (let patientId of Object.keys(prevOpened.current)) {
            if (prevOpened.current[patientId] && !chats[patientId]) viewing = false;
            prevOpened.current = chats;
            updateChat(patientId, viewing)
            .then(() => {
              prevOpened.current = chats;
            });
          }
        }
      }
    }
  }, [expanded, chats]);

  return (
    <Box display='flex' justifyContent='flex-end' alignItems='flex-end' className={classes.chatGrid}>
      {currentPage <  Math.ceil(Object.keys(chats).length / chatsPerPage) &&
          <IconButton onClick={() => setCurrentPage((prev) => (prev + 1))}>
              <ArrowBackIosIcon />
          </IconButton>
      }
      <Grid container justifyContent='flex-end' alignItems='flex-end' spacing={2}>
      {currentChats.length ? currentChats.map((id, i) => (
        <Grid item key={i}>
            <Accordion expanded={expanded[id] ? expanded[id] : false} className={expanded[id] ? classes.chatOpen : classes.chatClosed} onChange={handleChange(id)}>
                <AccordionSummary 
                  classes={{ expanded: expanded[id] ? classes.chatHeader : '' }} 
                  expandIcon={!user.current.patient && <CloseIcon onClick={(event) => { event.stopPropagation(); closeChat(id); }} 
                  onFocus={(event) => event.stopPropagation()} />}>
                    <Typography variant='body2'>{chats[id].name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.chatBody}>
                  {/* {console.log("open chats",allUserChats)} */}
                    <Chat chat={allUserChats[id]} recipientId={id} userId={user.current.userId} patient={user.current.patient} index={i}/>
                </AccordionDetails>
            </Accordion>
        </Grid>
        ))
        :
        <></>
      }
      </Grid>
      {currentPage > 1 &&
          <IconButton onClick={() => setCurrentPage((prev) => (prev - 1)) }>
              <ArrowForwardIosIcon />
          </IconButton>
      }
    </Box>
  )
}
