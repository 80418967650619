import { Grid, Paper, Typography, Tooltip } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import React, { useEffect, useState } from "react";
import { getClinicOption, getDoctorOption } from "../../shared/utilities/firebaseUtil";

const Announcement = ({ content, title, tootltipTitle, icon: Icon, md, style }) => (
    <Grid item xs={12} md={md}>
        <Paper style={{ padding: "15px 20px", marginBottom: "15px",  backgroundColor: '#e8effc', ...style }}>
            {!!title && 
                <h2 style={{ 
                    marginLeft: "35px",
                    marginTop: "4px",
                    display: "flex",
                    alignItems: 'center',
                    fontSize: "1.1em",
                }}>
                    <Tooltip title={tootltipTitle}>
                        <Icon  style={{ color: '#003FBA', marginRight: '10px' }} />
                    </Tooltip>
                    {title}
                </h2>
            }
        {!!content && <p style={{ padding: "0 35px "}}>{content}</p>}
        </Paper>
    </Grid>
);

const AnnouncementDisplay = () => {
    const [clinicAnnouncement, setClinicAnnouncement] = useState({ title: "", content: "" });
    const [doctorAnnouncement, setDoctorAnnouncement] = useState({ title: "", content: "" });

    const searchParams = new URLSearchParams(window.location.search);
    const whiteLabel = searchParams.get('org');
    const doctorId = searchParams.get('doctor');

    const setAnnouncements = async () => {
        if(whiteLabel) {
            const clinicSavedOptions = await getClinicOption(whiteLabel);
            if (clinicSavedOptions && clinicSavedOptions.announcement) {
                setClinicAnnouncement(clinicSavedOptions.announcement);
            }
        }

        if(doctorId) {
            const doctorSavedOptions = await getDoctorOption(doctorId);
            if (doctorSavedOptions && doctorSavedOptions.announcement) {
                setDoctorAnnouncement(doctorSavedOptions.announcement);
            }
        }
    }

    useEffect(() => {
        setAnnouncements();
    }, [whiteLabel, doctorId])

    const hasClinicAnnouncement = Boolean(clinicAnnouncement.title || clinicAnnouncement.content);
    const hasDoctorAnnouncement = Boolean(doctorAnnouncement.title || doctorAnnouncement.content);
    
    if(!hasClinicAnnouncement && !hasDoctorAnnouncement) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Typography style={{ marginBottom:"20px" }} variant="h3">
                <InfoIcon style={{ color:"#e47029", fontSize: 30 }} /> Announcement
            </Typography>

            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
            >
                {hasClinicAnnouncement && 
                    <Announcement
                        title={clinicAnnouncement.title}
                        content={clinicAnnouncement.content}
                        icon={BusinessIcon}
                        tootltipTitle="Clinic Announcement"
                        md={hasDoctorAnnouncement ? 6 : 12}
                        style={hasDoctorAnnouncement &&  { marginRight: "10px"} }
                    />
                }

                {hasDoctorAnnouncement && 
                    <Announcement
                        title={doctorAnnouncement.title}
                        content={doctorAnnouncement.content}
                        icon={AssignmentIndIcon}
                        tootltipTitle="Doctor Announcement"
                        md={hasClinicAnnouncement ? 6 : 12}
                        style={hasClinicAnnouncement &&  { marginLeft: "10px" } }
                    />
                }
            </Grid>
        </Grid>
    );
};

export default AnnouncementDisplay;
