import React from "react";
import PortonLogoImg from "../img/Porton_Default.png"; // Import the new image
// Style
import useStyles from "../styles/NewFooterStyle";

export default function ReportFooter({ address, contactNum, email }) {
  const classes = useStyles();

  // Your component code...
  return (
    <footer className={classes.footer}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <img
            src={PortonLogoImg}
            alt="Porton Logo"
            className={classes.portonImgFooter}
          />
        </div>
      </div>
    </footer>
  );
}
