/**
 * Global state mangement
 */

import create from 'zustand';

const videoStore = create(set => ({

    videoRatio: 0,
    // setVideoRatio: (videoRatio) => set({videoRatio}),

    // videoConstraints: (ratio) => {
    //     switch (ratio) {
    //         case 0:
    //             //default setting
    //             return {
    //                 width: {min: 320, ideal: 1280, max: 1920},
    //                 height: {min: 240, ideal: 720, max: 1080},
    //                 aspectRatio: {min: 1.333, ideal:1.333, max: 1.778}
    //             };
    //             break;
    //         case 1:
    //             return {
    //                 width: {max: 1920},
    //                 height: {max: 1080},
    //                 aspectRatio: {min: 1.333, ideal:1.333, max: 1.778}
    //             };
    //             break;
    //         case 2:
    //             return {
    //                 width: {max: 1280},
    //                 height: {max: 720},
    //                 aspectRatio: {min: 1.333, ideal:1.333, max: 1.778}
    //             };
    //             break;
    //         case 3:
    //             return {
    //                 width: {max: 858},
    //                 height: {max: 480},
    //                 aspectRatio: {min: 1.333, ideal:1.333, max: 1.778}
    //             };
    //             break;
    //         case 4:
    //             return {
    //                 width: {max: 352},
    //                 height: {max: 240},
    //                 aspectRatio: {min: 1.333, ideal:1.333, max: 1.778}
    //             };
    //             break;
    //     }
    // }

}));

export default videoStore;