import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  videoContainerPaper: {
    display: 'flex', 
    marginTop: '2%',
    flexDirection: "column",
  },

  videoContainerBox: {
    position:"relative", 
    overflow:"hidden",
  },

  chatDrawerPaperMiddle: {
    height:"100%",
  },

  videoControler: {
    position:"relative", 
    overflow:"hidden",
  },

  header: {
    margin: '15px 0 0 30px',
  },

  bottomBar: {
    position: 'absolute',
    bottom: "25px",
    width: "100%",

    [theme.breakpoints.down(630)]: {
      bottom: 0,
    },
  },
  video: {
    height: '100%',
    width: '100%',
    margin: 'auto',
  },

  localVideo: {
    position: 'absolute',
    maxWidth: '25%',
    width: 'auto',
    height: '26%',
    zIndex: '999',
    margin: '2%',
    right: '0',
    borderRadius: '20px'
  },

  videoContainer: {
    position: 'relative',
    backgroundColor: 'black',
    height: '37rem',
    margin: '30px',
    borderRadius: '30px',

    '& video': {
      borderRadius: '30px',
    },

    [theme.breakpoints.down(630)]: {
      margin: "5px",
    },
  },

  audioContainer: {
    backgroundColor: '#cb4d01', 
    height: '37rem',
    margin: '30px',
    borderRadius: '30px',

    [theme.breakpoints.down(630)]: {
      margin: "5px",
    },
  },

  circle: {
    height: '140px',
    width: '140px',
    background: 'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #EE8F37 0%, #E47029 100%)',
    borderRadius: '50%',
    display: 'block',
    position: 'relative',
    top: '40%',
    margin: "auto",
  },

  circleText: {
    position: 'relative',
    top: '38%',
    left: '30%',
    margin: 'auto',
    display: 'block',
  },

  timer: {
    position: 'relative',
    top: '120px',
    left: '52px'
  },

  videoLoading: {
    position: 'relative',
    top: '50%',
    left: '50%'
  },

  hiddenVideoForAudio: {
    height: 0, 
    width: 0
  },

  videoHelpBar: {
    height: 113,
  },

  button: {
    margin: '0 8px 0 8px',
    backgroundColor: "#EAEAEA",

    [theme.breakpoints.down(1000)]: {
      marginLeft: "5px",
      marginRight: "5px",
    }, 

    [theme.breakpoints.down(800)]: {
      width: "35px",
      height: "35px",
      minHeight: "35px",
      minWidth: "35px",
    },

    [theme.breakpoints.down(630)]: {
      width: "28px",
      height: "28px",
      minHeight: "28px",
      minWidth: "28px",
    },
  },

  moreButton: {
    position: 'relative',
    left: '35px',
    backgroundColor: "#EAEAEA",

    [theme.breakpoints.down(1000)]: {
      left: "30px",
    },
    [theme.breakpoints.down(800)]: {
      width: "35px",
      height: "35px",
      minHeight: "35px",
      minWidth: "35px",
    },

    [theme.breakpoints.down(630)]: {
      width: "28px",
      height: "28px",
      minHeight: "28px",
      minWidth: "28px",
      left: "15px",
    },
  },

  endButtonRight: {
    position: "relative",
    right: "35px",

    [theme.breakpoints.down(1000)]: {
      right: "30px",

      "& #video_res_button": {
        fontSize: "0.95em",
        lineHeight: "1em",
        marginRight: "5px",
        minWidth: "140px",
      }
    },

    [theme.breakpoints.down(768)]:{
      "& #video_res_button": {
        minWidth: "90px",
      }
    },

    [theme.breakpoints.down(630)]:{
      right: "15px",

      "& #video_res_button": {
        color: "grey",
        backgroundColor: 'pink'
      }
    },
  },

  endButtonLeft: {
    margin: '5px 0 0 30px',

    [theme.breakpoints.down(1000)]: {
      marginLeft: "35px",
    },
    [theme.breakpoints.down(630)]: {
      marginLeft: "15px",
    },
  },


  footer: {
    margin: "25px 5px",

    [theme.breakpoints.down(735)]: {
      margin: "25px 0",
    },
  },

  paper:{
    height: "100%",
    position: "absolute",
    bottom: 0,
    overflow: "hidden",
    justifyContent: "flex-end",
    border: '1px solid #eaeaea',
    marginTop: "30px",
    display: "grid",

    [theme.breakpoints.down(630)]: {
      marginTop: "5px",
    },
  },

  videoResMenuButton: {
    minWidth: 150,
    marginRight: "15px",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#eaeaea29",
    textTransform: "uppercase",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#EAEAEA",
      color: "black",
    },

    [theme.breakpoints.down(1135)]: {
      textTransform: "none",
      minWidth: 80,
      width: 'auto',
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    [theme.breakpoints.down(630)]: {
      color: 'grey',
      justifyContent: "flex-start",
    }
  },

  videoResMenuItem: {
    minWidth: 150,
    height: 35,
    '&:hover': {
      backgroundColor: '#FFB98E',
    },
  },
  videoResMenuItemSelected: {
    minWidth: 150,
    height: 35,
    backgroundColor: '#FFB98E',
  },

  content: {
    position:"relative",
    overflow:"hidden",

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",

    [theme.breakpoints.down(700)]: {
      transition: "none"
    }
  },

  contentShift: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down(700)]: {
      transition: "none"
    }
  },
}));
