import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Paper,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    withStyles
} from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircleRounded";
import VideoIcon from "@material-ui/icons/Videocam";
import PhoneIcon from "@material-ui/icons/Phone";
import useStyles from "../../../styles/DashboardStyle";
import useStore from "../../shared/store";
import moment from "moment-timezone";
import { api } from "../../shared/utilities/api";
import apiConfigs from "../../shared/utilities/apiConfigs";
import aptToday from '../../../img/apt-today.png';

const StyledTableHeadCell = withStyles({
    root: {
        fontSize: "1.25em",
        fontWeight: "bold",
        paddingTop: "20px",
        paddingBottom: "20px",

        '@media (max-width: 768px)': {
            display: "none",
        },
    },
  })(TableCell);

const minCounter = (org, dateTime) => {
    let now = org.location ? moment().tz(org.location) : moment();
    let aptTime = org.location
        ? moment(dateTime).tz(org.location)
        : moment(dateTime);
    let diff = aptTime.diff(now, "minutes");
    if (diff <= 15 && diff > 0) {
        return diff;
    }

    if (diff <= 0) {
        return true;
    }

    return false;
};

const AppointmentsTodayNew = ({ selectedProvider }) => {
    const classes = useStyles();
    const { org } = useStore();
    const [loading, setLoading] = useState(false);
    const [appointments, setAppointments] = useState();

    useEffect(() => {
        if (!selectedProvider) {
            return;
        }

        const providerNo = selectedProvider?.emrData[org._id]?.emrId;

        if (!providerNo) {
            return;
        }
        const filterApts = async () => {
            const fetchApts = await api(
                apiConfigs.baseOption(
                    "get",
                    "appointments/getTodayAppointmentsByDoctor",
                    { params: { org: org._id, providerNo } }
                )
            );
            if (fetchApts && fetchApts.status === 200) {
                let filteredApts = fetchApts.data.filter(
                    (apt) => apt.type === "Video"
                );
                setAppointments(filteredApts);
                setLoading(false);
            }
        };
        setLoading(true);
        filterApts();
    }, [org, selectedProvider]);

    if(!selectedProvider) {
        return (
            <Grid container component={Paper} className={classes.TodaysAppointments}>
                <Grid item xs={12}>
                    <Grid item xs={12} container justifyContent='center' className={classes.imageText}>
                        <img className={classes.logoImage} src={aptToday} alt="aptToday" width="200" />
                    </Grid>
                    <Typography variant="h5" align="center">
                        <strong>Please Select a Provider</strong>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography align="center" className={classes.aptCell}>
                        Today's appointments will show up here for the selected provider
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography style={{color:"#CB4D00"}} variant="h3">Appointment For Today</Typography>
            </Grid>

            <Grid item xs={12}>
                {loading || !appointments ? (
                    <Grid container component={Paper} className={classes.apt} justifyContent="center" >
                        <CircularProgress />
                    </Grid>
                ) : appointments.length ? (
                    <Grid container className={classes.columnPaper}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell>Time</StyledTableHeadCell>
                                        <StyledTableHeadCell>Name</StyledTableHeadCell>
                                        <StyledTableHeadCell>Type</StyledTableHeadCell>
                                        <StyledTableHeadCell>Reason</StyledTableHeadCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {appointments.map((appointment) => (
                                        <TableRow key={appointment.id} hover className={classes.rowWrapper}>
                                            <TableCell component="th" scope="row" className={classes.cellWrapper}>
                                                <div>
                                                    <strong>Time</strong>
                                                    {typeof minCounter(org, appointment.appointmentStartDateTime) === "number"
                                                        ? `IN ${minCounter(org, appointment.appointmentStartDateTime )} MINUTES` 
                                                        : `${appointment.startTime.substring(0, appointment.startTime.lastIndexOf(":" ))} - ${appointment.endTime.substring(0, appointment.endTime.lastIndexOf(":"))}`
                                                    }
                                               </div>
                                            </TableCell>
                                            <TableCell className={classes.cellWrapper}>
                                                <div >
                                                    <strong>Name</strong>
                                                    <div className={classes.iconCell}>
                                                        <AccountIcon color="primary"/>
                                                        {appointment.name}
                                                    </div>
                                               </div>
                                            </TableCell>
                                            <TableCell className={classes.cellWrapper}>
                                                <div>
                                                    <strong>Type</strong>

                                                    <div className={classes.iconCell}>
                                                        {appointment.type === "Video"
                                                            ? <><VideoIcon style={{ color: "#CB4D00" }}/> Video Call</>
                                                            : appointment.type === "Telephone"
                                                                ? <><PhoneIcon /> Voice Call</>
                                                                : appointment.type
                                                        }
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cellWrapper}>
                                                <div>
                                                    <strong>Reason</strong>
                                                    {appointment.reason}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ) : (
                    <Grid container component={Paper} className={classes.TodaysAppointments}>
                            <Grid item xs={12} container justifyContent='center' className={classes.imageText}>
                                <img className={classes.logoImage} src={aptToday} alt="aptToday" width="200" />
                            </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                No Appointments Today
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.aptCell}>
                            <Typography align="center">
                                You currently have no upcoming appointment for
                                the day
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default AppointmentsTodayNew;
