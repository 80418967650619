import moment from "moment";

const DATE_MASK = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

export const dateFormatter = (date) => {
    let oldDate = date.split("-");
    return (
        DATE_MASK[parseInt(oldDate[1])] +
        " " +
        oldDate[2] +
        ", " +
        oldDate[0]
    );
};

export const timeFormatter = (openTime, sessionMin) => {
    //format the time
    let newTime = openTime.split(":");
    let openHour = parseInt(newTime[0]);
    let openMin = parseInt(newTime[1]);

    const timeMomemt = moment();
    timeMomemt.hour(openHour);
    timeMomemt.minute(openMin);

    const open = timeMomemt.format("hh:mm a");
    timeMomemt.add(sessionMin, "minutes");
    const close = timeMomemt.format("hh:mm a");

    return { open, close };
};
