import React, { useEffect, useRef, useState } from 'react';
import { Grid, Paper,Drawer, ClickAwayListener, useMediaQuery } from '@material-ui/core';

import VideoContainerHeader from "./VideoContainerHeader"
import VideoContainerBody from "./VideoContainerBody"
import VideoContainerBottomBar from "./VideoContainerBottomBar"

import CustomAlert from '../../../shared/components/CustomAlert';
import useStore from '../../../shared/store';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'webrtc-adapter';
import json2mq from 'json2mq';
import clsx from 'clsx';
// Components
import useStyles from '../../../../styles/VideoContainerStyle/VideoContainer';
import VideoChat from './VideoChat';

export default function VideoContainer({ callInfo, setStartCall, setDisablePatientChat }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({
    doctor: null,
    userId: null,
    conn: null,
    localStream: null
  });

  //Alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [notification, setNotification] = useState(false);
  const [chat, setChat] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const classes = useStyles();
  const middleScreen = useMediaQuery(json2mq({ maxWidth: 1150 }));
  const smallScreen = useMediaQuery(json2mq({ maxWidth: 630 }));
  
  const { user } = useStore();

  const paperClass = {
    paper: classes.paper
  }

  const videoContainerBox = useRef(null)
  const chatButton = useRef(null)
  //const localVideoRef = useRef(null);
  // On mount get user id
  useEffect(() => {
    const patient = localStorage.getItem('session');
    if (patient) {
      setUserInfo((userInfo) => ({ ...userInfo, userId: JSON.parse(patient).id, doctor: false }));
    } else {
      setUserInfo((userInfo) => ({ ...userInfo, userId: user._id, doctor: true }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If chat does not exist, have the doctor create it
  useEffect(() => {
    if (callInfo.patient) {
      firebase.firestore()
      .collection('chats')
      .doc(`${user._id}:${callInfo.patient.id}`)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          firebase.firestore()
          .collection('chats')
          .doc(`${user._id}:${callInfo.patient.id}`)
          .set({
            chatWindow: {
              viewing: true,
              time: firebase.firestore.Timestamp.fromDate(new Date())
            },
            messages: [],
            users: [user._id, callInfo.patient.id],
            chatUsername: callInfo.patient.name,
            videoChat: {
              [user._id]: firebase.firestore.Timestamp.fromDate(new Date()),
              [callInfo.patient.id]: firebase.firestore.Timestamp.fromDate(new Date())
            }
          })
          .catch(error => console.log(error))
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the chat closes, update the last viewed timestamps
  useEffect(() => {
    if (!chatOpen && users.length) {
      const recipientId = users.filter((user) => user !== userInfo.userId);

      firebase.firestore()
        .collection('chats')
        .doc(!callInfo.patient ? `${recipientId}:${userInfo.userId}` : `${userInfo.userId}:${recipientId}`)
        .update({
          [`videoChat.${userInfo.userId}`]: firebase.firestore.Timestamp.fromDate(new Date())
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatOpen, users]);

  // On mount listen for chat messages
  useEffect(() => {
    if (users.length) {
      const recipientId = users.filter((user) => user !== userInfo.userId);

      const unsubscribe = firebase.firestore()
      .collection('chats')
      .where("users", "array-contains", userInfo.userId)
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.data().users.includes(recipientId[0])) {
            setChat(doc.data())
          }
        })
      })
  
      if (unsubscribe) return () => unsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (chat) {
      if (chatOpen) {
        setNotification(false);
      } else {
        if (chat.messages.length) {
          const latestMessage = chat.messages[chat.messages.length - 1];
          const recipientId = users.filter((user) => user !== userInfo.userId)[0];

          if ((latestMessage.sender === recipientId) && (moment(latestMessage.time.toDate()).isAfter(moment(chat.videoChat[userInfo.userId].toDate())))) {
            setNotification(true);
          } else {
            setNotification(false);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, chatOpen]);

  const setAlert = (message, type) => {
    setType(type);
    setMessage(message);
    setOpenAlert(true);
  }

  const handleChatDrawerClose = (event) => {
    setChatDrawerOpen(false)
  }

  const handleChatDrawerClickAway = (event) => {
    if(chatButton.current.contains(event.target))
      return
    setChatDrawerOpen(false)
  }

  let boxWidth = !middleScreen && chatDrawerOpen ? videoContainerBox.current.clientWidth - 280 : "100%";

  return (
    <Paper elevation={3} className={classes.videoContainerPaper}>
      <VideoContainerHeader callInfo={callInfo}/>

      <Grid item xs={12} sm={12} lg={12} ref={videoContainerBox} className={classes.videoContainerBox}>
        <Grid
          container
          direction="column" 
          className={clsx(classes.content, {
            [classes.contentShift]: chatDrawerOpen,
          })}
          style={{ width: boxWidth }}
        >
          <Grid item xs>
            <Grid container direction="column" className={classes.videoControler}>
              <Grid item xs>
                <VideoContainerBody
                  callInfo={callInfo}
                  user={user}
                  users={users}
                  setUsers={setUsers}
                  setAlert={setAlert}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  loading={loading}
                  setLoading={setLoading}
                  setStartCall={setStartCall}
                  setDisablePatientChat={setChatDrawerOpen}
                  isFullScreen={isFullScreen}
                />
              </Grid>

              <Grid item xs className={classes.videoHelpBar} wrap='nowrap'>
                <VideoContainerBottomBar
                  loading={loading}
                  smallScreen={smallScreen}
                  middleScreen={middleScreen}
                  userInfo={userInfo}
                  callInfo={callInfo}
                  setAlert={setAlert}
                  onFullScreenClick={() => setIsFullScreen(!isFullScreen)}
                  setChatDrawerOpen={setChatDrawerOpen}
                  notification={notification}
                  chatButton={chatButton}
                />
              </Grid>

              {middleScreen && 
                <ClickAwayListener onClickAway={handleChatDrawerClickAway}>
                 <Drawer
                    docked={false}
                    anchor="right"
                    variant="persistent"
                    classes={paperClass}
                    open={chatDrawerOpen}
                    onClose={handleChatDrawerClose}
                  >
                    <div className={classes.chatDrawerPaperMiddle}>
                      <VideoChat 
                        userId={userInfo.userId}
                        isDoctor={userInfo.doctor}
                        users={users}
                        chat={chat}
                      />
                   </div>
                 </Drawer>
               </ClickAwayListener>
             }
            </Grid>
          </Grid>
        </Grid>

        {!middleScreen &&
          <ClickAwayListener onClickAway={handleChatDrawerClickAway}>
            <Drawer
              anchor="right"
              variant="persistent"
              classes={paperClass}
              open={chatDrawerOpen}
              onClose={handleChatDrawerClose}
            >
              <div>
                <VideoChat 
                  userId={userInfo.userId}
                  isDoctor={userInfo.doctor}
                  users={users}
                  chat={chat}
                />
              </div>
            </Drawer>
          </ClickAwayListener>
        }
      </Grid>

      <CustomAlert description={message} type={type} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
    </Paper>
  )
}
