import React, { useEffect, useState } from 'react'
import { Grid, Typography, Paper } from '@material-ui/core';
import useIndexStyle from '../../../styles/DashboardStyle/index';
import useStyle from '../../../styles/DashboardStyle/components/PatientGreeting';
import VideocamIcon from '@material-ui/icons/Videocam';
import MicIcon from '@material-ui/icons/Mic';
import PreCallTest from '../../preCallTest/PreCallTest';
import { OutlinedOrangeButtonLarge, OrangeButton } from '../../../styles/CustomButtons';

export default function PatientGreeting({ patientPosition, setPatientJoinCall, callReady, callInfo }) {

  const [patient, setPatient] = useState(null);
  const [showPreCallTest, setShowPreCallTest] = useState(false);
  const classesIndex = useIndexStyle();
  const classes = useStyle();

  useEffect(() => {
    const session = localStorage.getItem('session');
    setPatient(JSON.parse(session));
  }, []);

  const getFirstName = () => {
    return patient.name.split(" ")[0];
  };

  const addPositionText = (pos) => {
    const lastDigit = pos[pos.length - 1];
    let abbr;
    switch (lastDigit) {
      case '1':
        abbr = 'st ';
        break;
      case '2':
        abbr = 'nd ';
        break;
      case '3':
        abbr = 'rd ';
        break;
      default:
        abbr = 'th ';
    }
    return abbr;
  };

  const renderWaitingWelcome = () => {
    return (
      <>
      <Grid item xs={12}>
        <Grid container component={Paper} className={classesIndex.columnPaper}>
          <Grid item xs={12} container justifyContent="center" className={classes.mainTitle}>
            <Typography variant='h3'>Hello, <span className={classesIndex.userName}>{ getFirstName() }</span> !</Typography> 
        </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant='h4' className={classes.subTitle}>You are the <span className={classesIndex.userName}>
              { patientPosition.toString() + addPositionText(patientPosition.toString()) }</span> patient in line 
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" className={classes.message}>
            <Typography variant='body1'>Please do a pre-call test and wait patiently, I will get to you as soon as possible </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" className={classes.button}>
            <OutlinedOrangeButtonLarge onClick={() => setShowPreCallTest(true)}> PRE-CALL TEST </OutlinedOrangeButtonLarge>
          </Grid>
        </Grid>
      </Grid>
      {showPreCallTest && <PreCallTest showPreCallTest={showPreCallTest} setShowPreCallTest={setShowPreCallTest}/>}
      </>
    )

  };

  const renderCallJoinable = () => {
    return (
      <>
      <Grid item xs={12}>
        <Grid container component={Paper} className={classesIndex.columnPaper}>
          <Grid item xs={12} container justifyContent="center" className={classes.mainTitle}>
            <Typography variant='h3'>Hello, <span className={classesIndex.userName}>{ getFirstName() }</span> </Typography> 
        </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant='h4' className={classes.subTitle}>
              I am ready to meet with you!
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" className={classes.message}>
            <Typography variant='body1'>Please join the call as soon as you are ready</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" className={classes.button}>
            {console.log(callInfo)}
            {callInfo.type === 'audio' &&
              <OrangeButton className={classes.enterSessionButton} variant='contained' onClick={() => setPatientJoinCall(true)}><MicIcon />&nbsp;&nbsp; Enter Audio Session </OrangeButton>
            }
            {callInfo.type === 'video' &&
              <OrangeButton  className={classes.enterSessionButton} variant='contained' onClick={() => setPatientJoinCall(true)}><VideocamIcon />&nbsp;&nbsp; Enter Video Session </OrangeButton>   
            }
          </Grid>
        </Grid>
      </Grid>
      </>
    )

  };

  return (
      <Grid container spacing={3}>
          { 
            patient &&
            <>
            <Grid item xs={12}>
              <Typography variant='h3'>{`Dr. ${patient.doctor}'s Virtual Walk In Room` }</Typography> 
            </Grid>
            {callReady ? renderCallJoinable() : renderWaitingWelcome()}
          </>
        }
      </Grid>
  );
}
