import { Menu, MenuItem, useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import useStyles from '../../../../styles/VideoContainerStyle/VideoContainer';

const VideoQualityButton = ({
	recordVideoMenuPosition,
	videoAnchorEl,
	videoResTag,
	scaleRatio,
	videoMenuOpen,
	closeMenu,
	videoResLimiter,
	setScaleRatio
}) => {
	const classes = useStyles();
  const showFullButton = useMediaQuery(json2mq({ maxWidth: 1135 }));

	return (
		<>
			<span
				id="video_res_button"
				onClick={recordVideoMenuPosition}
				className={classes.videoResMenuButton}
			>
				{showFullButton ? videoResTag : (videoResTag + " VIDEO") }
			</span>
			
			<Menu
				anchorEl={videoAnchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: 'top', horizontal: "center" }}
				transformOrigin={{vertical: "bottom", horizontal: "center" }}
				open={videoMenuOpen}
				onClose={closeMenu}
			>
				<MenuItem
					className={scaleRatio === 1 ? classes.videoResMenuItemSelected:classes.videoResMenuItem}
					disabled={videoResLimiter.FHD_Menu_Button_Disable}
					onClick={() => {closeMenu() ; setScaleRatio(1)}}
				>
						1080p
				</MenuItem>
				<MenuItem
					className={scaleRatio === 2 ? classes.videoResMenuItemSelected:classes.videoResMenuItem}
					disabled={videoResLimiter.HD_Menu_Button_Disable}
					onClick={() => {closeMenu() ; setScaleRatio(2)}}
				>
					720p
				</MenuItem>
				<MenuItem
					className={scaleRatio === 3 ? classes.videoResMenuItemSelected:classes.videoResMenuItem}
					disabled={videoResLimiter.SD_Menu_Button_Disable}
					onClick={() => {closeMenu() ; setScaleRatio(3)}}
				>
					480p
				</MenuItem>
				<MenuItem
					className={scaleRatio === 4 ? classes.videoResMenuItemSelected:classes.videoResMenuItem}
					disabled={videoResLimiter.LD_Menu_Button_Disable}
					onClick={() => {closeMenu() ; setScaleRatio(4)}}
				>
					320p
				</MenuItem>
			</Menu>
		</>
	);
}

export default VideoQualityButton;
