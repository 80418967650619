import React, {useEffect, useState} from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  OutlinedInput,
  ThemeProvider, Typography
} from '@material-ui/core'
import useStyle from '../../../styles/DashboardStyle/components/PatientCheckIn';
import {ReactComponent as WelcomeImage} from '../../shared/public/img/welcome.svg';
import {OrangeButton} from '../../../styles/CustomButtons';
import {customInputTheme} from '../../../styles/LoginStyle/LoginPage';
import CustomAlert from '../../shared/components/CustomAlert';
import {v4 as uuidv4} from 'uuid';
import apiConfigs from '../../shared/utilities/apiConfigs';
import {api} from '../../shared/utilities/api';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {getClinicOption} from "../../shared/utilities/firebaseUtil";
import useStore from "../../shared/store";
import Grid from "@material-ui/core/Grid";

const PatientCheckIn = ({ setPatientCheckedIn }) => {

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [inputValid, setInputValid] = useState(true);
  const [doctor, setDoctor] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [org, setOrg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //Alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  // Clinic Option
  const [clinicClosed, setClinicClosed] = useState({
    closed: false,
    status: 0,
    opHour: {},
  })

  // current Day and Time
  const [currentDay, SetCurrentDay] = useState({
    dateToNum:{
      'Sunday':0,
      'Monday':1,
      'Tuesday':2,
      'Wednesday':3,
      'Thursday':4,
      'Friday':5,
      'Saturday':6,
    },
    today:null,
    time:0,
    weekday:0,
  })

  // away and closed pop-up
  const [closedScreenOpen, setClosedScreenOpen] = useState(false);
  const [awayScreenOpen, setAwayScreenOpen] = useState(false);

  const store = useStore()

  const classes = useStyle();
  // PROVIDER_STATUS constant
  const {PROVIDER_STATUS} = store;

  useEffect(() => {
    // initial and set current day/time
    let todayN = new Date();
    SetCurrentDay({
      dateToNum:{
        'Sunday':0,
        'Monday':1,
        'Tuesday':2,
        'Wednesday':3,
        'Thursday':4,
        'Friday':5,
        'Saturday':6,
      },

      today:todayN,
      time:todayN.getHours() * 100 + todayN.getMinutes(),
      weekday: todayN.getDay()
    });
  },[])
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if(!params.get('doctor')) { 
      return;
    }

    //open check-in pop-up
    const session = localStorage.getItem('session');
    if (!session) {

      const providerLastname = async () => {
        const fetchProviderLastname = await api(apiConfigs.baseOption('get', 'organization/getOrgDoctorInfo', 
          { params: { orgId: params.get('org'), doctorId: params.get('doctor') } }
        ));

        if (fetchProviderLastname && fetchProviderLastname.status === 200) {
          setDoctor(fetchProviderLastname.data.last_name || fetchProviderLastname.data.lastname);
        }

        store.setOrg(fetchProviderLastname.data.org);
      }
      setDoctorId(params.get('doctor'));
      providerLastname()
      setOpen(true);
      setOrg(params.get('org'));
    } else {
      setPatientCheckedIn(true);
    }
  }, [setPatientCheckedIn])

  const setClinicUnavailable = () => {
    // two scenario for popup
    setOpen(false);
    if(clinicClosed.status === PROVIDER_STATUS.PROVIDER_AWAY){
      //away
      setAwayScreenOpen(true);
    }
    else if(clinicClosed.status === PROVIDER_STATUS.PROVIDER_UNAVAILABLE){
      //unavailable
      setClosedScreenOpen(true);
    }
  }

  useEffect(() => {
    // trigger when clinic is closed
    if (clinicClosed.closed) {
      setClinicUnavailable()
    }
  }, [clinicClosed.closed])

  
 let part = /^[a-zA-Z ]*$/;

 let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;

  const RenderPatientPopUp = () => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            classes={{paper: classes.dialogContainer}}
            disableBackdropClick={true}
        >
          <WelcomeImage className={classes.imageContainer}/>
          {doctor ?
              <h3 style={{fontSize:"20px",color:"#cb4d01"}} className={classes.header}>{`Welcome to Dr. ${doctor}'s Virtual Room`}</h3>
              : ''}
          <DialogContent>
            <p className={classes.subtext}>
              {`Please check in to let Dr. ${doctor} know that you are here`}
            </p>
            <ThemeProvider theme={customInputTheme}>
              <FormControl error={!inputValid}>
                <OutlinedInput
                    fullWidth
                    placeholder="Your Full Name"
                    onChange={handleTextInput}
                    className={classes.textField}
                    classes={{input: classes.textFieldInner}}
                />
                {!inputValid && <FormHelperText id="component-error-text">Please enter a correct first and last
                  name</FormHelperText>}

                {!regName.test(input)  || !part.test(input) || input.length > 31 ? <sub style={{ color: "#D0342C",marginTop:"10px" }}> Please enter a correct First Name and Last Name </sub> : ""}

              </FormControl>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
                       
            {isLoading 
              ? <CircularProgress  style={{margin: '0 auto 15px'}}/>
              : (
                  <OrangeButton disabled={isLoading} onClick={() => {
                    handleSubmit();
                    setIsLoading(true)
                  }} color="primary" autoFocus className={classes.button}>
                    CHECK-IN
                  </OrangeButton>
                )
            }
            
          </DialogActions>
        </Dialog>
    )
  }

  const ClinicClosePopUp = () => {
    return (
        <Dialog
            open={closedScreenOpen}
            onClose={() => setClosedScreenOpen(false)}
            classes={{paper: classes.dialogContainer}}
            disableBackdropClick={true}
        >
          <Typography>{""}</Typography>
          {doctor ?
              <DialogTitle className={classes.header}>{`${doctor} Unavailable`}</DialogTitle>
              : ''}
          <DialogContent>
            <DialogContentText className={classes.subtext}>
              {`${doctor} is currently unavailable. Please refer to the clinic hours below.`}
            </DialogContentText>

            <DialogContentText className={classes.headerTime}>
              {'Operation Hours:'}
            </DialogContentText>
            {PrintSchedule()}
          </DialogContent>
          <DialogActions>
            <OrangeButton onClick={() => {
              setClosedScreenOpen(false)
            }} color="primary" autoFocus className={classes.button}>
              OK
            </OrangeButton>
          </DialogActions>
        </Dialog>
    )
  }
  const ProviderAwayPopUp = () => {
    return (
        <Dialog
            open={awayScreenOpen}
            onClose={() => setAwayScreenOpen(false)}
            classes={{paper: classes.dialogContainer}}
            disableBackdropClick={true}
        >
          <Typography>{""}</Typography>
          {doctor ?
              <DialogTitle className={classes.header}>{`Dr. ${doctor} is Away`}</DialogTitle>
              : ''}
          <DialogContent>
            <DialogContentText className={classes.subtext}>
              {`Dr. ${doctor} is currently away, but you can still check in to the queue.`}
            </DialogContentText>
            <DialogContentText className={classes.headerTime}>
              {'Operation Hours:'}
            </DialogContentText>
            {PrintSchedule()}

          </DialogContent>
          <DialogActions>
            <OrangeButton onClick={() => {
              setAwayScreenOpen(false)
            }} color="primary" autoFocus className={classes.button}>
              No Thanks
            </OrangeButton>
            <OrangeButton onClick={() => {
              setAwayScreenOpen(false);
              addPatientToQueue(JSON.parse(localStorage.getItem('session')))
            }}
                          color="primary" autoFocus className={classes.button}>
              Check-In
            </OrangeButton>
          </DialogActions>
        </Dialog>
    )
  }
  const PrintSchedule = () => {
    // print time schedule if the clinic is closed / provider is away
    return (
        <DialogContentText>
          {clinicClosed.opHour ?
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {Object.entries(clinicClosed.opHour).map(([key, value]) => {
                      return(
                          <Typography
                            className={currentDay.weekday === currentDay.dateToNum[key] ? classes.subtextTime : classes.subtext}
                          >
                            {key}
                          </Typography>
                      )
                  })}
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {Object.entries(clinicClosed.opHour).map(([key, value]) => {
                      return(
                          <Typography
                              className={currentDay.weekday === currentDay.dateToNum[key] ? classes.subtextTime : classes.subtext}>
                            {timeFormatter(value.open, value.close)}</Typography>
                      )
                    })}
                  </Box>
                </Grid>
              </Grid>
              : ''}
        </DialogContentText>
    )
  }
  const timeFormatter = (open, close) => {
    // formatter the time from 24 to 12
    if(open === "Closed"){
      return "Closed"
    }else{
      let openTemp = "";
      let closeTemp = "";

      let openSplit = open.split(":")
      let closeSplit = close.split(":")

      if (parseInt(openSplit[0]) > 12) {
        openTemp = parseInt(openSplit[0]) - 12 + ":" + openSplit[1] + "pm";
      } else {
        openTemp = parseInt(openSplit[0]) + ":" + openSplit[1] + "am";
      }

      if (parseInt(closeSplit[0]) > 12) {
        closeTemp = parseInt(closeSplit[0]) - 12 + ":" + closeSplit[1] + "pm";
      } else {
        closeTemp = parseInt(closeSplit[0]) + ":" + closeSplit[1] + "am";
      }
      return (openTemp + " - " + closeTemp)
    }
  }
  const sortTime = (opHour) =>{
    // sort the time by weekday (sun, mon .... sat)
    let SortedHour = Object.keys(opHour).map((key)=>{
      return [key,opHour[key]]
    }).sort((a,b)=>{
      return currentDay.dateToNum[a[0]] - currentDay.dateToNum[b[0]];
    })
    let HourDict = {}
    SortedHour.forEach((date)=>{
      HourDict[date[0]] = date[1]
    })
    return HourDict;
  }

  const getClinicClose = async (org) => {
    //get Clinic options from firebase
    try {
      return getClinicOption(org);
    } catch (error) {
      console.log(error)
    }
  }

  const getClinicCloseCondition = async () => {
    // set close clinic condition
    const params = new URLSearchParams(window.location.search);
    const ClinicOption = await getClinicClose(params.get('org')); //get options from fire

    let SortedHour = sortTime(ClinicOption.OperationHour); //sort the operation hour
    if (ClinicOption.ClinicScheduleSave && ClinicOption.userID === params.get('doctor')) {
      //scheduling is activated
      for (const [key, value] of Object.entries(SortedHour)){
        if (currentDay.weekday === currentDay.dateToNum[key]) {
          const openSplit = value.open.split(":");
          const closeSplit = value.close.split(":");

          let openTime = parseInt(openSplit[0]) * 100 + parseInt(openSplit[1]);
          let closeTime = parseInt((closeSplit[0])) * 100 + parseInt(closeSplit[1]);

          if (openTime > currentDay.time || currentDay.time > closeTime) {
            // not in working hours
            setClinicClosed({closed: true, status: PROVIDER_STATUS.PROVIDER_UNAVAILABLE, opHour: SortedHour});
            return true;
          }
        }
      }
    }
    if (ClinicOption.ClinicCloseSave && ClinicOption.userID === params.get('doctor')) {
      // provider status option is activated
      if (ClinicOption.ProviderStatusSave === PROVIDER_STATUS.PROVIDER_AVAILABLE) {
        //available
        return false;
      }
      //other status
      setClinicClosed({closed: true, status: ClinicOption.ProviderStatusSave, opHour: SortedHour});
      return true
    }
    return false
  }

  const createPatientSession = async (name) => {
    // TODO: Add private and public key pair for message encryption
    const session = {
      id: uuidv4(),
      name: name,
      settings: {
        video: null,
        audioIn: null,
        audioOut: null
      },
      doctor: doctor,
      doctorId: doctorId,
      org: org,
      lastUpdated: moment().format(),
      time: moment().format(),
    }

    const result = await api(
      apiConfigs.baseOption(
        'POST', 
        'auth/patient', 
        {
          headers: {
            "Content-Type": 'application/json', 
            "Access-Control-Allow-Origin": '*'
          }, 
          data: { id: session.id, org: session.org }
        }
      )
    );


    if (result.status === 200) {
      try {
        await firebase.auth().signInWithCustomToken(result.data.token);
        localStorage.setItem('session', JSON.stringify(session));

        // return a result:
        // true, no effect -> add patient to queue
        // false: using clinic option feature
        return await Promise.resolve(getClinicCloseCondition());

      } catch(error) {
        setType('error');
        setMessage("Unable to authenticate with server. Please try again later.");
        setOpenAlert(true);
      }
     
    } else {
      setType('error');
      setMessage("Unable to authenticate with server. Please try again later.");
      setOpenAlert(true);
    }
  };

  const addPatientToQueue = (patient) => {
    firebase.firestore()
    .collection('patient-queue')
    .doc(patient.id)
    .set({
        id: patient.id,
        name: patient.name,
        org: patient.org,
        doctor: patient.doctor,
        doctorId: patient.doctorId,
        removed: false,
        inCall: false,
        permissions: { audio: false, video: false },
        time: firebase.firestore.Timestamp.fromDate(new Date()),
        lastUpdated: firebase.firestore.Timestamp.fromDate(new Date())
    }).then(() => {
        console.log("Patient successfully  added to queue.");
        setOpen(false);
        setPatientCheckedIn(true);
    }).catch((error) => {
        console.log("Error adding document:", error);
    });
  };

  const handleSubmit = () => {
    if (input.trim().length === 0) {
      console.log("length false");
      setInputValid(false);
    }
    else if (!/^(([A-Za-z]{1,20} [A-Za-z]{1,20} [A-Za-z]{1,20})|([A-Za-z]{1,20} [A-Za-z]{1,20}))$/.test(input.trim())){
      console.log("pattern false")
      setInputValid(false);
    } else {
      createPatientSession(input.trim()).then((results) => {
        if(!results){
          addPatientToQueue(JSON.parse(localStorage.getItem('session')));
        }
      })
    }
    inputValid ? setIsLoading(true) : setIsLoading(false)
  };

  const handleTextInput = (e) => {
    setInput(e.target.value);
    setIsLoading(false)
    if(input && !inputValid) setInputValid(true);
  }

  return (
    <div >
      {RenderPatientPopUp()}
      {ClinicClosePopUp()}
      {ProviderAwayPopUp()}
      <CustomAlert description={message} type={type} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
    </div>
  );
}
export default PatientCheckIn;