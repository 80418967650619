import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Button, CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import settingStyle from "../../styles/DialogStyle/components/settingsPageStyles";
import HumburgerButton from "../shared/HumburgerButton";
import useStore from "../shared/store";
import { api } from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";

const MessagePage = ({ smallScreenView, handleMenuClick }) => {
    const classes = settingStyle();
    const { user } = useStore();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getProviderMessages = async () => {
        try {
            const userId = user._id;
            const orgId = user.orgs[0];
            const result = await api(
                apiConfigs.baseOption("get", `providerMessages/${orgId}_${userId}`)
            );
            console.log("result", result);
            // Update this line to access the messages correctly
            setMessages(result.data.messages || []); // Now this correctly assigns an array
        } catch (error) {
            console.error("Error retrieving messages:", error);
            setError("Failed to load messages.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            getProviderMessages();
        }
    }, [user]);

    return (
        <>
            <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />

            <Container style={{ padding: "0px" }}>
                <Box display="flex" style={{ marginTop: 32 }}>
                    <Typography className={classes.labelLarge}>Message History</Typography>
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100px" }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : messages.length > 0 ? (
                    <Box display="flex" flexDirection="column" gap={2}>
                        {messages.map((message) => (
                            <Card key={message._id} variant="outlined">
                                <CardContent>
                                    <Typography variant="h6">Patient: {message.patientDemoNo}</Typography>
                                    <Typography variant="body2">Date: {new Date(message.date).toLocaleString()}</Typography>
                                    <Button
                                        variant="contained"
                                        href={message.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{color:"#CB4D00"}}
                                    >
                                        Download
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                ) : (
                    <Typography>No messages found.</Typography>
                )}
            </Container>
        </>
    );
};

export default MessagePage;
