import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    Button,
    OutlinedInput,
    Collapse,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    IconButton,
    FormControl,
    FormLabel,
    FormHelperText,
    Paper,
    Select,
    Typography,
    withStyles,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import FileUploadIcon from '../components/icons/FileUploadIcon';
import { OutlinedOrangeButton } from '../../../styles/CustomButtons';
import useStyles from "../../../styles/DialogStyle/components/helpPageStyles";
import { decrypt } from '../../shared/utilities/decrypt.js'
import { api } from "../../shared/utilities/api";
import apiConfigs from "../../shared/utilities/apiConfigs";
import ConfirmationDialog from '../../shared/ConfirmationDialog';
import CustomAlert  from "./CustomAlert";
import useStore from "../../shared/store";

const contactInquiryTypes = [
	{
		key: "message_clinic",
		label: "Message Clinic",
	},
	{
		key: "reschedule_or_cancel",
		label: "Reschedule Appointment",
	},
	{
		key: "missed_appointment",
		label: "Missed Appointment",
	},
    {
        key: "doctor_not_joined",
        label: "Doctor Not Available for Video Call",
    }
];

const StyledField = withStyles({
    root: {
        marginTop: '15px',
    },
    fieldLabel: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '20px',
        lineHeight: '28px',
        marginBottom: '10px',
    },
    errorMessage: {
        color: 'red',
    },
    textCounter: {
        fontSize: "12px",
        paddingTop: "5px",
        textAlign: "right",
        color: "#767676",
    },
})(({ id, component, label, value, placeholder, multiline, rows, onChange, fullWidth, classes, showTextCounter, maxLength, helperText, ...restProps }) => {
    const Component = component || OutlinedInput; 

    return (
        <FormControl fullWidth size="small" variant="standard">
            <Grid container className={classes.root}>
                <Grid item xs={fullWidth ? 12 : 3}>
                    <FormLabel htmlFor={id} className={classes.fieldLabel}>{label}</FormLabel>
                </Grid>

                <Grid item xs={fullWidth ? 12 : 9}>
                    <Component 
                        id={id} 
                        placeholder={placeholder} 
                        value={value} 
                        variant="outlined"
                        fullWidth
                        multiline={multiline}
                        rows={rows}
                        size="small"
                        onChange={onChange}
                        maxLength={maxLength}
                        {...restProps}
                        
                    />
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <FormHelperText className={classes.errorMessage}>{helperText}</FormHelperText>
                        {showTextCounter &&
                            <span className={classes.textCounter}>
                                {value ? value.length : 0}/{maxLength}
                            </span>
                        }
                    </div>

                    
                </Grid>
            </Grid>
        </FormControl>
    )
});

const TechnicalSupport = ({ emailType }) => {
    const classes = useStyles();
    const { org, user } = useStore();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReportPopUp, setShowReportPopUp] = useState(false); 
    const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isDoctorNameValid, setIsDoctorNameValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isPatientCommentValid, setIsPatientCommentValid] = useState(true);
    const [isInquiryTypeValid, setIsInquiryTypeValid] = useState(true);
    const [providers, setProviders] = useState([]);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [inquiryType, setInquiryType] = useState("");
    const [patientComment, setPatientComment] = useState('');
    const [attachments, setAttachments] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showCustomAlert, setShowCustomAlert] = useState(false);

    const fileInputRef = useRef(null);

    const isDoctor = user.roles && user.roles[0] === "doctor";
    const isPatient = !user.roles;
    const isContactClinictEmail = emailType === 'contact_clinic';
    const hasClinicEmail = isContactClinictEmail && org?.communicationSetting?.email;

    const getOrgProviders = async () => {
        let org;
        if(isPatient) {
            const session = JSON.parse(localStorage.getItem('session'));
            org = session.org; 
        } else {
            org  = user.default_org;
        }

        const fetchOrgProviders = await api(
            apiConfigs.baseOption("get", "appointments/getOrgProviders", {
                params: { org_id: org, limit: -1 },
            })
        );

        if (fetchOrgProviders && fetchOrgProviders.status === 200) {
            const filteredProviders = fetchOrgProviders.data.data.data.filter(
                (doctor) => {
                    // null/undefined check is needed here because it is possible for doctors to not have appointment settings setup
                    if (doctor.modes) {
                        return (
                            doctor.modes.includes("001") ||
                            doctor.modes.includes("002")
                        );
                    } else {
                        return false;
                    }
                }
            );
            return filteredProviders;
        } else {
            return fetchOrgProviders.status;
        }
    };

    const getNumIssues = () => {
        let issues = 0;

        const isValidName = /^[a-zA-Z ]{2,30}$/.test(name);
        setIsNameValid(isValidName);

        const isValidEmail = /^\S+@\S+\.\S+$/.test(email);
        setIsEmailValid(isValidEmail);

        if(!isValidName || !isValidEmail) {
            issues++;
        }
        
        if(!isDoctor) {
            const isValidDoctorName =  !!doctorName;
            setIsDoctorNameValid(isValidDoctorName);

            if(!isValidDoctorName) {
                issues++;
            }
        }

        if(isContactClinictEmail) {
            const isValidInquiryType = !!inquiryType;
            setIsInquiryTypeValid(isValidInquiryType);

            if(!isValidInquiryType) {
                issues++;
            }
        }

        const isValidPatientComment = !!patientComment && /[0-9a-zA-Z!@#$%^&*]{1,300}/.test(patientComment);
        setIsPatientCommentValid(isValidPatientComment);

        if(!isValidPatientComment){
            issues++;
        }
        return issues;
    };

    const getFileAttachment = () => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if (attachments === null) {
                resolve([]);
            }
            reader.readAsDataURL(attachments);
            reader.addEventListener('load', (event) => {
                if (event?.target?.result) {
                    resolve([{ path: event.target.result }]);
                }
                reject(new Error('Error while reading file'));
            });
        });
    };

    const handleShowSendToTechSupportPopUp = () => setShowReportPopUp(!showReportPopUp);

    const clearFilelds = () => {
        setIsLoading(false);
        setEmail('');
        setName('');
        setPatientComment('');
        setDoctorName('');
        setAttachments(null);
        isContactClinictEmail && setInquiryType('');
        if(fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = ''; 
        }
    };

    const handleSendEmailToSupport = async (e) => {
        e.preventDefault();
		let numIssues = getNumIssues();
		if (numIssues > 0) {
			return;
		}

        setIsLoading(true);

        const attachment = !!attachments ? await getFileAttachment() : null;

        const body = {
            data: {
                org: {
                    logo_link: org.logo_link,
                    id: org.id,
                    name: org.name,
                },
                attachments: attachment,
                doctorName,
                patientComment,
                [isPatient ? 'patientEmail' : 'email']: email,
                [isPatient ? 'patientName' : 'name']: name,
                userType: user.roles ? user.roles[0] : 'patient',
                emailType
           },
        };

        if(isContactClinictEmail) {
            body.data.inquiryType = inquiryType;
            body.data.sendTo = decrypt(org.communicationSetting.email);
        }

        await api(apiConfigs.baseOption('POST', 'techSupport/sendEmail', body)).then(resp => {
            if(resp.status === 200) {
                setShowReportPopUp(false);
                setShowConfirmationPopUp(true);
                clearFilelds(); 
            } else {
                setIsLoading(false);
                setErrorMessage(resp.error.response.data);
                setShowCustomAlert(true);
            }
        });
    };

    const handleAttacnmetsChange = (e) => setAttachments(e.target.files[0]);
    const handleRemoveFile = () => {
        setAttachments(null);
        fileInputRef.current.value = '';
    };

    const handleCancel = () => {
        setShowReportPopUp(false);
        clearFilelds(); 
    };

    useEffect(() => {
        if(!isDoctor){
            getOrgProviders()
            .then((res) => {
                if (res.length < 0) {
                    console.log(
                        "No Providers with Audio or Video call appointments Enabled"
                    );
                }
                setProviders(res);
            })
            .catch((e) => {
                console.log(e);
            });
        }
    }, [user]);

    const rootClassNames = clsx({
        [classes.rootExpanded]: isExpanded,
        [classes.root]: !isExpanded,
    });

    const labelClassNames = clsx({
        [classes.labelExpanded]: isExpanded,
        [classes.labelCollapsed]: !isExpanded,
    });

    return (
        <>
            <Grid
                container
                component={Paper}
                elevation={3}
                className={rootClassNames}
            >
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box className={classes.labelWithEdit} onClick={() => setIsExpanded(!isExpanded)}>
                        <Typography className={labelClassNames}>
                            {isContactClinictEmail ? "Сontact Message" : "Technical Support"}
                        </Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: isExpanded,
                                })}
                                aria-expanded={isExpanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>

 
                <Grid item xs={12} >
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid container spacing={4} className={classes.expandedWrapper}>
                            <Grid item xs={12}>
                                <Typography className={classes.expandedDescription}>
                                    {isContactClinictEmail 
                                        ? "We know your inquiry is important to you, and we’re here to help! If your inquiry is non-urgent or is a general question, please feel free to send us an email. Our support team will promptly get in touch." 
                                        : "We’re here to Help! To ensure your experience with our software is seamless, we encourage you to report any issues you encounter using the link below. Our technical support team is dedicated to resolving your concerns."
                                    }
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.expandedReportRow}>
                                <Button className={classes.reportIssueBtn} onClick={handleShowSendToTechSupportPopUp}>
                                    {isContactClinictEmail ? "CONTACT YOUR CLINIC" : "Report an issue"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

            <Dialog open={showReportPopUp}>
                <span className={classes.reportIssueHeader}>
                    {isContactClinictEmail ? "Need Assistance ?" : "REPORT AN ISSUE"}
                </span>

                {isContactClinictEmail 
                    ? hasClinicEmail ? <span className={classes.reportIssueDescription}>This form is only used for contacting your clinic. Please fill out all the required fields and DO NOT use this form for a medical emergency.</span> : ""
                    : <span className={classes.reportIssueDescription}>We understand the importance of your inquiry, and our support team is here to assist you. To report any issues use the Form provided below.</span>
                }

                {(!isContactClinictEmail || hasClinicEmail) 
                    ?
                        <Grid container className={classes.formWrapper}>
                            <StyledField
                                id="fullName"
                                label="Full Name"
                                placeholder="Please Enter Your Full Name"
                                value={name}
                                error={!isNameValid}
                                helperText={!isEmailValid && "Please enter a valid name"}
                                required
                                onChange={(e) => setName(e.target.value)}
                            />
                            <StyledField
                                id="email"
                                label="Email"
                                placeholder="Please Enter Your Email"
                                error={!isEmailValid}
                                helperText={!isEmailValid && "Please enter a valid email address"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />

                            {isContactClinictEmail && 
                                <StyledField
                                    id="inquiryType"
                                    label="Inquiry Type"
                                    component={Select}
                                    native
                                    required
                                    onChange={(e) => setInquiryType(e.target.value)}
                                    error={!isInquiryTypeValid}
                                    helperText={!isInquiryTypeValid && "Please enter a valid inquiry type"}
                                >
                                    <option value="" defaultValue>SELECT INQUIRY TYPE</option>
                                    {contactInquiryTypes.map(({ key, label }) => <option value={label} key={key}>{label.toUpperCase()}</option>)}
                                </StyledField>
                            }
                            {!isDoctor && 
                                <StyledField
                                    id="doctor"
                                    label="Doctor"
                                    component={Select}
                                    native
                                    required
                                    onChange={(e) => setDoctorName(e.target.value)}
                                    error={!isDoctorNameValid}
                                    helperText={!isDoctorNameValid && "Please provide doctor name"}
                                >
                                    <option value="" defaultValue>SELECT DOCTOR</option>
                                    {providers.length > 0 && providers.map(({_id, contact_name}) => <option value={contact_name} key={_id}>{contact_name.toUpperCase()}</option>)}
                                </StyledField>
                            }

                            <StyledField
                                id="describeIssue"
                                label="Describe your issue"
                                placeholder="Please Describe your issue here"
                                multiline
                                rows={5}
                                maxLength="300"
                                fullWidth
                                showTextCounter
                                value={patientComment}
                                required
                                error={!isPatientCommentValid}
                                helperText={!isPatientCommentValid && "Please provide additional comment about the issue"}
                                onChange={(e) => setPatientComment(e.target.value)}
                            />

                            <Grid item xs={12} >
                                <input
                                    accept="image/*"
                                    className={classes.uploadInput}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleAttacnmetsChange}
                                    ref={fileInputRef}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button 
                                        variant="outlined"
                                        startIcon={<FileUploadIcon />}
                                        size="small"
                                        className={classes.uploadButton}
                                        component="span"
                                    >
                                        Click to Upload
                                    </Button>
                                </label>

                                {attachments && 
                                    <div className={classes.fileRow}>
                                        <div style={{display: 'flex'}}>
                                            <AttachFileIcon className={classes.attachIcon}/>
                                            <span>{attachments.name}</span>
                                        </div>
                                        <ClearIcon onClick={handleRemoveFile} className={classes.clearIcon}/>
                                    </div>
                                }
                            
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.acceptedFilesLabel}>Accepted file types are: PNG and JPG</div>
                            </Grid>
                        </Grid>
                    :   <Box style={{margin: "35px", lineHeight: 1.5, fontSize: "1.2em"}} >There is no contact email provided from the clinic. Please directly call the clinic for support.</Box>
                }

                <DialogActions className={classes.actionButtons}>
                    {isLoading ? <CircularProgress style={{margin: '5px auto 10px'}}/> :
                        <>
                            <OutlinedOrangeButton onClick={handleCancel} className={classes.fullWidth} disabled={isLoading}>CANCEL</OutlinedOrangeButton> 
                            {isContactClinictEmail 
                                ? hasClinicEmail 
                                    ? <Button className={classes.sendReportBtn} onClick={handleSendEmailToSupport} disabled={isLoading}>SEND TO CLINIC</Button>
                                    : null
                                : <Button className={classes.sendReportBtn} onClick={handleSendEmailToSupport} disabled={isLoading}>SEND TECH SUPPORT</Button>
                            }
                        </>
                    }
                </DialogActions>
            </Dialog>

            <ConfirmationDialog 
                open={showConfirmationPopUp}
                title="Your email has been received"
                text={<>We are currently reviewing your email.<br/>Thank you for your patience.</>}
                closeButtonTitle="OK"
                onClose={() => setShowConfirmationPopUp(false)}
            />

            <CustomAlert 
                description={errorMessage}
                type="error" 
                openAlert={showCustomAlert}
                setOpenAlert={setShowCustomAlert}
            />
        </>
    );
};

export default TechnicalSupport;
