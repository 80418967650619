import React, {useRef, useEffect, useState} from 'react'
import useStyles from '../../../../styles/VideoContainerStyle/VideoContainer';
import { doOffer, startSession, doAnswer, doCandidate, getCallStatus, setCallStatus } from '../../../shared/utilities/FirebaseConnectionCalls';
import { WebRTC } from '../../../shared/utilities/WebRTC';
import 'firebase/firestore';
import moment from 'moment';
import apiConfigs from '../../../shared/utilities/apiConfigs';
import { api } from '../../../shared/utilities/api';
import {subscribeCameraState} from "./messageShare"
import { Typography, CircularProgress} from '@material-ui/core';

import videoStore from "./VideoStatus";
import useStore from "../../../shared/store";

export default function VideoContainerBody(props) {
	const {callInfo, user, users, setUsers, 
		setAlert, userInfo, setUserInfo,
		loading, setLoading, setStartCall, setDisablePatientChat, isFullScreen} = props

	const classes = useStyles();
	const remoteVideoRef = useRef({current:{srcObject:null}});
	const localVideoRef = useRef(null);
	const rtc = useRef(null);
	const interval = useRef(null);
	const listener = useRef(null);

	const [timer, setTimer] = useState(0);


	// video quality adjustment states
	const {setVideoRatio, videoRatio, videoConstraints} = videoStore();
	const [remoteQuality, setRemoteQuality] = useState(0);

	//Package setting
	const {SIMPLE_PACKAGE, SOLO_PACKAGE, currentPackage} = useStore();

	// On mount initialize our local stream
	useEffect(() => {
		switch(currentPackage){
			case SIMPLE_PACKAGE:
				setVideoRatio(3);
				break;
			case SOLO_PACKAGE:
				setVideoRatio(2);
		}
	  const constraints = videoConstraints(videoRatio);
	  rtc.current = new WebRTC([
	    { 
	      urls:'turn:booking.portonhealth.com:3478',
	      username: 'portonturn',
	      credential: 'Porton123!'
	    },
	    {
	      urls: [
					"stun:stun.l.google.com:19302",
					"stun:stun1.l.google.com:19302",
					"stun:stun2.l.google.com:19302",
					"stun:stun3.l.google.com:19302",
					"stun:stun4.l.google.com:19302",
					// "stun:stun.services.mozilla.com",
				]
	    },
	  ]);
	  rtc.current.initLocalStream(callInfo.type, constraints)
			.then((localStream) => {
				setUserInfo((userInfo) => ({ ...userInfo, localStream } ));
				localVideoRef.current.srcObject = localStream;
				return localStream;
			}).then((localStream) => {
				const conn = rtc.current.initPeerConnection(localStream)
				.catch(error => setAlert(error.message, 'error'));
				return conn;
			}).then((conn) => {
				setUserInfo((userInfo) => ({ ...userInfo, conn }));
				if (user.roles && user.roles[0] === 'doctor') {
					rtc.current.createOffer(conn, callInfo.patient.id, user._id, callInfo.type, callInfo, doOffer)
					.catch(error => setAlert(error.message, 'error'));
				}
			})
			.catch((error) => {
				setAlert(error.message, 'error')
			})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
	  if (callInfo.type === 'audio') {
	    interval.current = setInterval(() => {
	      setTimer((timer) => timer + 1)
	    }, 1000)
	
	    return () => {
	      clearInterval(interval.current);
	    }
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const setSenderVideoRes = (conn, ratio) => {
	// 	// adjust out-going video resolution base on ratio defined in VideoStatus.js
	// 	const constraints = videoConstraints(ratio);
	// 	try{
	// 		conn.getSenders().forEach((senders) => {
	// 			if(senders.track && senders.track.kind == "video") {
	// 				senders.track.applyConstraints(constraints).then(()=>{
	// 						//console.log("success change res")
	// 					}).catch(error => {
	// 						console.log(error);
	// 				})
	// 			}
	// 		})
	// 	}catch(error) {
	// 		console.log(error);
	// 	}
	// }

	//useEffect(() => {
	//	setSenderVideoRes(userInfo.conn, videoRatio);
	//},[videoRatio])

	useEffect(() =>{
		// get incoming resolution request
		if(remoteVideoRef.current.srcObject){
			//console.log("start incoming! "+ remoteQuality)
			setIncomingVideoRes(userInfo.conn,remoteQuality);
		}
	},[remoteQuality])

	const setIncomingVideoRes = (conn, remoteQuality)=> {
		// adjust out-going video resolution base on remote ratio defined in VideoStatus.js
		// will be removed after testing and replaced by setSenderVideoRes

		if(remoteQuality && remoteQuality !== 0){ // != 0 prevent exception from client(patient)
			let constraints = videoConstraints(remoteQuality);
			//console.log("incoming constraints");
			try {
				conn.getSenders().forEach((senders) => {
					if(senders.track && senders.track.kind === "video") {
						senders.track.applyConstraints(constraints).then(()=>{
							//console.log("success change res")
						}).catch(error => {
							console.log(error);
						})
					}
				})
			}catch(error) {
				setAlert("Selected Video resolution isn't supported", ' error ')
				console.log(error);
			}
		}
	}

	// Start WebRTC communcation when local setup is complete
	useEffect(() => {
	  if (userInfo.userId && userInfo.conn && userInfo.localStream && userInfo.doctor !== null) {
	    if (!userInfo.doctor) {
	      // Case where patient refreshes there page, then restart the process using the original offer
	      getCallStatus(userInfo.userId).then((status) => {
	        if (status === 'candidate') {
	          setCallStatus(userInfo.userId, 'reconnect')
	        }
	      })
	    }
	    subscribeCameraState(userInfo.conn,  localVideoRef.current)
	    listener.current = startSession(userInfo.userId, userInfo.doctor ? callInfo.patient.id : undefined, userInfo.doctor, handleSession);
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.userId, userInfo.conn, userInfo.localStream, userInfo.doctor])


	const handleSession = (update) => {
	  switch(update.type) {
	    case 'offer':
	      // Patient recieves the offer and sends answer
	      if (!userInfo.doctor) {
	        rtc.current.listenForConnectionsEvents(userInfo.userId, userInfo.doctor ? callInfo.patient.id : userInfo.userId,
				userInfo.conn, remoteVideoRef.current, doCandidate).then(() =>{
				rtc.current.sendAnswer(userInfo.userId, userInfo.conn, update, doAnswer);
			}).catch(error => setAlert(error.message, 'error'))
	      }
	      break;
	      
	    case 'answer':
	      // Doctor recieves the patients answer and starts the call
	      if (userInfo.doctor) {
	        rtc.current.listenForConnectionsEvents(userInfo.userId, userInfo.doctor ? callInfo.patient.id : userInfo.userId,
				userInfo.conn, remoteVideoRef.current, doCandidate).then(() => {
	            rtc.current.startCall(userInfo.conn, update);

	        }).catch(error => setAlert(error.message, 'error'))
	      }

	      break;

	    case 'candidate':
	      rtc.current.addCandidate(userInfo.conn, update)
	      .catch(error => setAlert(error.message, 'error'))
	      setLoading(false);
	      break;

	    case 'reconnect':
	      if (userInfo.doctor) {
	        rtc.current.createOffer(userInfo.conn, callInfo.patient.id, user._id, callInfo.type, callInfo, doOffer)
	        .catch(() => setAlert('Error restablishing connection.', 'error'))
	      }
	      break;

	    case 'end':
	      checkAndAddMeetingHistory(update.startTime, update.endTime);
	      rtc.current.close(userInfo.conn);
    	  localVideoRef.current.srcObject.getTracks().forEach((track) => {console.log("closing"); track.stop()})
	      listener.current();
	      setDisablePatientChat(true);
	      setStartCall(false);
	      break;

	    default:
	      break;
	  }

	  // Check if the other user wants to mute their mic and mute your remote feed
	  try {
	    const peerId = Object.keys(update.muted).filter((user) => user !== userInfo.userId)[0];
	    remoteVideoRef.current.muted = update.muted[peerId];
	  } catch(error) {
	    console.log("Videoref is null")
	  }

	  try {
		  //when peers' quality setting updated
		  const peerId = Object.keys(update.quality).filter((user) => user !== userInfo.userId)[0];
		  //console.log("set Quality test " + update.quality[peerId])
		  setRemoteQuality(update.quality[peerId]);
	  }catch(error){
		  console.log(error);
	  }

	  // Set users for chat
	  if (!users.length) {
	    if (update.users) setUsers(update.users);
	  }
	}

	const getNameInitials = () => {
	  const name = callInfo.doctor ? callInfo.doctor.split(" ") : callInfo.patient.name.split(" ");
	  if (name.length === 2) {
	    return `${name[0][0].toUpperCase()} ${name[1][0].toUpperCase()}`
	  } else {
	    return `${name[0][0].toUpperCase()}`
	  }
	};

	const checkAndAddMeetingHistory = (startTime, endTime) => {
		let currentDate = moment();
		if(user && user.roles && user.roles[0] === "doctor"){
			api(apiConfigs.baseOption(
	          'POST', 
	          `appointments/meetingHistory`,
	          {
	          	headers: {"Content-Type": 'application/json'},
	          	data: {
	          		doctor_id: user._id,
	          		patient: callInfo.patient.name,
	          		month: currentDate.month() + 1,  // monent month is from 0-11 so need + 1
	          		year: currentDate.year(),
	          		start_time: moment(`${startTime.seconds}`, "X").toDate(),
	          		end_time: moment(`${endTime.seconds}`, "X").toDate(),
	          		createdAt: currentDate.toDate(),
	          	}
	          },
	        ));
		}
	}

	useEffect(() => {
		if(isFullScreen) {
			const videoElement = remoteVideoRef?.current;

			if (videoElement.requestFullscreen) {
				videoElement.requestFullscreen();
			} else if (videoElement.webkitRequestFullscreen) {
				videoElement.webkitRequestFullscreen();
			} else if (videoElement.mozRequestFullScreen) {
				videoElement.mozRequestFullScreen();
			} else if (videoElement.msRequestFullscreen) {
				videoElement.msRequestFullscreen();
			} else if (videoElement.webkitEnterFullscreen) {
				videoElement.webkitEnterFullscreen(); 
			}  else {
				throw new Error("fullscreenElement is not supported by this browser");
			}
		}
	}, [isFullScreen])
	return (
		<div>
		  {callInfo.type === 'video'
				? 
					<div className={classes.videoContainer}>
						<video id="stream" ref={localVideoRef} muted autoPlay playsInline className={classes.localVideo}/>
						<video id="remoteStream" ref={remoteVideoRef} autoPlay playsInline className={classes.video}/>
						<CircularProgress className={classes.videoLoading} style={!loading && {display: 'none'}} />
					</div>
				:
					<div className={classes.audioContainer}>
						<div className={classes.hiddenVideoForAudio}>
							<video ref={localVideoRef} muted autoPlay playsInline />
							<video ref={remoteVideoRef} autoPlay playsInline/>
						</div>

						<span className={classes.circle}>
							<span className={classes.circleText}><Typography variant='h3'>{ getNameInitials() }</Typography></span>
							<span className={classes.timer}>{ timer < (60*60*60) ? moment.utc(timer * 1000).format('mm:ss') : moment.utc(timer * 1000).format('HH:mm:ss')}</span>
						</span>
					</div>
		  }
		</div>
	)
}