/*
	name: Zhilong 

	this file contain information for state handling with different component
	within video container component.

	state sharing mainly between the bottom bar and the video body component
*/

import PubSub from 'pubsub-js';  

const CAM_MGS = "camera";
const SHARE_MGS = "share";

// input: {camState: true/false}
export const publishCameraState = (state) => {
	PubSub.publish(CAM_MGS, state);
}

// input: {shareState: true/false}
export const publishShareState = (state) => {
	PubSub.publish(SHARE_MGS, state);
}

const swaitchToVideoStream = (navigator, peerConnection, localVideo) =>
	navigator.mediaDevices.getUserMedia({ video: true })
		.then(stream => {
			localVideo.srcObject = stream;
			const newTrack = stream.getVideoTracks()[0];

			peerConnection.getSenders().forEach(sender => {
				if(sender.track && sender.track.kind === "video") {
					sender.replaceTrack(newTrack);
				}
			});
		})
		.catch(error => console.log(error));

export const subscribeCameraState = (peerConnection, localVideo) => {
	PubSub.subscribe(CAM_MGS, (mgs, state) => {
		if(!state.camOffState) {// turning on the camera
			navigator.mediaDevices.getUserMedia({video:true})
				.then(stream => {
					localVideo.srcObject = stream;
					let newTrack = stream.getVideoTracks()[0];

					peerConnection.getSenders().forEach(sender => {
						if(sender.track && sender.track.kind === "video") {
							sender.replaceTrack(newTrack);
						}
					});
				})
				.catch(error => console.log(error));
		} else 	{// turn off camera
			localVideo.srcObject.getVideoTracks().forEach((track) => {
					track.enabled = false;
					// give it a time to disable the track
					setTimeout(() => {
						track.stop();
					}, 500);
			})
		}
	})

	// share state
	PubSub.subscribe(SHARE_MGS, (mgs, state) => {
		if(!state.screenShareOffState) {// turning on the screen share
			navigator.mediaDevices.getDisplayMedia({cursor: true})
				.then(stream => {
					stream.getVideoTracks()[0].addEventListener("ended", (p) => {
						swaitchToVideoStream(navigator, peerConnection, localVideo);
					});

					localVideo.srcObject = stream;
					let newTrack = stream.getVideoTracks()[0];

					peerConnection.getSenders().forEach(sender => {
						if(sender.track && sender.track.kind === "video") {
							sender.replaceTrack(newTrack);
						}
					});
				})
				.catch(error => console.log(error));
		} else {
			swaitchToVideoStream(navigator, peerConnection, localVideo);
		}
	})
}


/*export const subscribeCameraState = (peerConnection, localVideo) => 
{
	let newstream = navigator.mediaDevices.getDisplayMedia({});
let newtrack = newstream.getTracks()[1];
if(newtrack.kind !== 'video')
    throw new Error('Eek!?');
pc.getSenders().forEach(async s => {
    if(s.track && s.track.kind === 'video')
        await s.replaceTrack(newtrack);
});
}*/



