import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  mainTitle: {
    marginTop: '30px',
    marginBottom: '20px',
    fontWeight: 600
  },

  button: {
    marginTop: '20px',
    marginBottom: '30px'
  },

  subTitle: {
    fontWeight: 600
  },

  message: {
    padding: '0 4% 0 4%'
  },
  enterSessionButton: {
    background: 'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #EE8F37 0%, #E47029 100%)',
    borderRadius: 8,
    color: 'black',
    border: 'black',
    minWidth: 273,
    width: 'auto',
    minHeight: 56,
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      width: 'auto',
      color: 'white',
      border: 'none',
    }
  },
}));
