import React, { useState } from 'react';
import { Grid, Typography, IconButton, TextField, Box } from '@material-ui/core';
import useStyles from '../../../../styles/VideoContainerStyle/VideoChat';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import firebase from 'firebase/app';
import 'firebase/firestore';
import clsx from 'clsx';

export default function VideoChat({ userId, isDoctor, users, chat }) {

  const [message, setMessage] = useState('');
  const recipientId = users.filter((user) => user !== userId);
  const classes = useStyles();

  const handleSendMessage = (e) => {
    e.preventDefault();
   
    if (e.target.value) {
      firebase.firestore()
      .collection('chats')
      .doc(!isDoctor ? `${recipientId}:${userId}` : `${userId}:${recipientId}`)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion(
        {
          message: message,
          sender: userId,
          time: firebase.firestore.Timestamp.fromDate(new Date())
        }),
      }).then(() => {
        scrollToBottom();
      })
      setMessage('');
    }
  }
  
  /*

  useEffect(() => {
    scrollToBottom()
  });
*/
  const scrollToBottom = () => {
    document.getElementById('message-container-video').scrollIntoView(false);
  }

  return (
    <Grid container direction="column" className={classes.chat}>
      <Grid item xs={9}>
        <Box className={classes.chatContainer} id="message-container-video">
          {chat && chat.messages && chat.messages.map((message, i) => (
            <div key={i} className={message.sender === userId ? classes.messageSender : classes.messageRecipient}>
              <Typography className={ message.sender === userId ? clsx(classes.message, classes.messageColor): classes.message}>{message.message}</Typography>
            </div>
          ))}
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Grid container alignItems='center' className={classes.chatInputContainer}>
          <Grid item container xs={4} justfiy="space-between" alignItems='center'>
            <Grid item xs={6}>
                <IconButton disabled>
                    <AccountBalanceWalletIcon />
                </IconButton>
            </Grid>
            <Grid item xs={6}>
                <IconButton>
                    <AttachFileIcon />
                </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <TextField
              className={classes.chatTextField}
              placeholder='Send a message...'
              fullWidth
              value={message}
              maxRows={2}
              onChange={(e) => {setMessage(e.target.value)}}
              InputProps={{
                  disableUnderline: true
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage(e);
                }
              }}
            />
          </Grid>
        </Grid>

      </Grid>
     {/* <Grid item xs={9} style={{width: '100%'}}>
        <Grid className={classes.chatContainer}>
          <div id={"message-container-video"}>
          {chat && chat.messages && chat.messages.map((message, i) => {
            return (
              <div key={i} className={message.sender === userId ? classes.messageSender : classes.messageRecipient}>
                <Typography className={ message.sender === userId ? clsx(classes.message, classes.messageColor): classes.message}>{message.message}</Typography>
              </div>  
            )
          })}
          </div>

        </Grid>
      </Grid>    

      <Grid item xs={3}>
        <Grid container alignItems='center' className={classes.chatInputContainer} >
          <Grid item xs className={classes.action}>
            <Grid container justfiy="space-between" alignItems='center'>
              <Grid item xs={2}>
                  <IconButton disabled>
                      <AccountBalanceWalletIcon />
                  </IconButton>
              </Grid>
              <Grid item xs={2}>
                  <IconButton>
                      <AttachFileIcon />
                  </IconButton>
              </Grid>
              <Grid item xs={8}>
              <TextField
                className={classes.chatTextField}
                placeholder='Send a message...'
                fullWidth
                value={message}
                maxRows={2}
                onChange={(e) => {setMessage(e.target.value)}}
                InputProps={{
                    disableUnderline: true
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage(e);
                  }
                }}
              />
              </Grid>
            </Grid>
          </Grid>    
        </Grid>
      </Grid>  */}
    </Grid>
  )
}
