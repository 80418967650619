import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Link} from '@material-ui/core';
import useStyles from '../../../styles/DashboardStyle/components/Chat';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useStore from '../../shared/store';
import firebase from 'firebase/app';
import 'firebase/firestore';
import clsx from 'clsx';


// the state variables sendPayment and state has been temporarily commented out for MVP use.
const Chat = ({ chat, recipientId, userId, patient, index }) => {
    const [message, setMessage] = useState("");
    const { user } = useStore();
    const classes = useStyles();

    //  const [sendPayment, setSendPayment] = useState({turnOnPayment:false, receivePayment:false, amount:""})

    const [state, setState] = useState({
        currentUploadedFile: "",
        currentUploadedFileName: "",
    });

    const storageRef = firebase.storage();
    useEffect(() => {
        // If a chat document does not exist, create one
        if (!chat) {
            let name;
            switch (user.roles[0]) {
                case "healthOrgAdmin":
                    name = `Admin ${user.firstname ? user.firstname : ""}`;
                    break;
                case "doctor":
                    name = `Doctor ${user.lastname ? user.lastname : ""}`;
                    break;
                case "admin":
                    name = `Admin ${user.firstname ? user.firstname : ""}`;
                    break;
                case "oa":
                    name = `Office Assistant ${
                        user.firstname ? user.firstname : ""
                    }`;
                    break;
                default:
                    name = `Admin`;
            }

            firebase
                .firestore()
                .collection("chats")
                .doc(`${userId}:${recipientId}`)
                .set({
                    chatWindow: {
                        viewing: true,
                        time: firebase.firestore.Timestamp.fromDate(new Date()),
                    },
                    messages: [],
                    users: [userId, recipientId],
                    chatUsername: name,
                    videoChat: {
                        [userId]: firebase.firestore.Timestamp.fromDate(
                            new Date()
                        ),
                        [recipientId]: firebase.firestore.Timestamp.fromDate(
                            new Date()
                        ),
                    },
                });
        }
        // eslint-disable-next-line
    }, [chat]);

    useEffect(() => {
        scrollToBottom();
    });

    const handleSendMessage = (e) => {
        e.preventDefault();

        if (e.target.value) {
            firebase
                .firestore()
                .collection("chats")
                .doc(
                    patient
                        ? `${recipientId}:${userId}`
                        : `${userId}:${recipientId}`
                )
                .update({
                    messages: firebase.firestore.FieldValue.arrayUnion({
                        message: message,
                        sender: userId,
                        // Add an id so an entry becomes unique
                        time: firebase.firestore.Timestamp.fromDate(new Date()),
                        // Add URL link if needed
                    }),
                })
                .then(() => {
                    scrollToBottom();
                });

            setMessage("");
        }
    };

    const scrollToBottom = () => {
        document
            .getElementById(`message-container-${index}`)
            .scrollIntoView(false);
    };

    const attachTheFile = (event) => {
        event.preventDefault();
        setState({
            ...state,
            currentUploadedFile: event.target.files[0],
            currentUploadedFileName: event.target.files[0].name,
        });
        event.target.value = null;
    };

    useEffect(() => {
        if (state.currentUploadedFile.size > 5 * 1024 * 1024) {
            alert("The maximum file size is 5MB. Please try again.");
        } else if (state.currentUploadedFile !== "") {
            storageRef
                .ref(`/files/${state.currentUploadedFile.name}`)
                .put(state.currentUploadedFile)
                .then((data) => {
                    data.ref.getDownloadURL().then((url) => {
                        firebase
                            .firestore()
                            .collection("chats")
                            .doc(
                                patient
                                    ? `${recipientId}:${userId}`
                                    : `${userId}:${recipientId}`
                            )
                            .update({
                                messages:
                                    firebase.firestore.FieldValue.arrayUnion({
                                        message: state.currentUploadedFileName,
                                        sender: userId,
                                        // Add an id so an entry becomes unique
                                        time: firebase.firestore.Timestamp.fromDate(
                                            new Date()
                                        ),
                                        // Add URL link if needed
                                        url: url,
                                    }),
                            })
                            .then(() => {
                                scrollToBottom();
                            });
                    });
                });

            setState({
                ...state,
                currentUploadedFile: "",
                currentUploadedFileName: "",
            });
        }
    }, [state.currentUploadedFileName]);

    return (
        <Grid container>
            <Grid item xs={12} className={classes.content}>
                <div id={`message-container-${index}`}>
                    {chat
                        ? chat.messages.map((message, i) => {
                              return (
                                  <div
                                        key={i}
                                        className={
                                            message.sender === userId
                                                ? classes.messageSender
                                                : classes.messageRecipient
                                        }
                                  >
                                      {message.url ? (
                                          <Typography
                                              className={
                                                  message.sender === userId
                                                      ? clsx(
                                                            classes.message,
                                                            classes.messageColor
                                                        )
                                                      : classes.message
                                              }
                                              style={{
                                                  paddingBottom: "8px",
                                                  textAlign: "left",
                                              }}
                                          >
                                              Sent One File
                                              <Link
                                                  href={message.url}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  style={
                                                      message.sender === userId
                                                          ? { backgroundColor: "#ffffff" }
                                                          : { backgroundColor: "#FF914D" }
                                                  }
                                                  className={
                                                      message.sender === userId
                                                          ? clsx(
                                                                classes.message,
                                                                classes.messageColor
                                                            )
                                                          : classes.message
                                                  }
                                              >
                                                  {message.message}
                                              </Link>{" "}
                                          </Typography>
                                      ) : (
                                          <Typography
                                              className={
                                                  message.sender === userId
                                                      ? clsx(
                                                            classes.message,
                                                            classes.messageColor
                                                        )
                                                      : classes.message
                                              }
                                          >
                                              {message.message}
                                          </Typography>
                                      )}
                                  </div>
                              );
                          })
                        : ""}
                </div>
            </Grid>
            <Grid item xs={12} className={classes.action}>
                <Grid container alignItems="center">
                    {/* This grid containing the wallet has been commented out for MVP use
                     */}
                    {/*user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') ?     
          <Grid item xs={2}>
              <IconButton onClick={() => setSendPayment({turnOnPayment:true})}>
                      <AccountBalanceWalletIcon/>
                  </IconButton>
            </Grid> : "" 
            <Grid item xs={2}>
              
            </Grid> */}

                    <Grid item xs={2}>
                        <input
                            style={{ display: "none" }}
                            className={classes.input}
                            multiple
                            id="fileButton"
                            onChange={attachTheFile}
                            type="file"
                        />
                        <label htmlFor="fileButton">
                            <AttachFileIcon style={{ cursor: "pointer" }} />
                        </label>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField
                            className={classes.chatTextField}
                            placeholder="Start a Chat"
                            fullWidth
                            value={message}
                            maxRows={2}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSendMessage(e);
                                }
                            }}
                        />
                    </Grid>

                    {/* Payment charging and payment request has been commented out
         for MVP use
         */}
                    {/*user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') &&
        <PaymentCharging
                open={sendPayment.turnOnPayment}
                close={() => setSendPayment({ ...sendPayment, turnOnPayment: false, receivePayment: true  })}
                sendPayment={sendPayment}
                setSendPayment={setSendPayment}
            /> 
         }


{ user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') && sendPayment.amount!='' ?

<PaymentRequest
                open={sendPayment.receivePayment}
                close={() => setSendPayment({ ...sendPayment, receivePayment: false  })}
                sendPayment={sendPayment}
                setSendPayment={setSendPayment}
            /> : ""
        */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Chat;
