import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    message: {
        fontSize: 17,
        color: "black",
        border: "1px solid #E47029",
        borderRadius: 4,
        display: "inline-block",
        backgroundColor:"none",
        padding: "5px 7px 7px",

    },
    messageSender: {
        marginTop: "15px",
        paddingLeft: "15px",
        marginRight: "100px",


    },
    messageRecipient: {
        marginTop: "15px",
        textAlign: "right",
        paddingRight: "15px",
        marginLeft: "100px",
        
    },
    content: {
        overflowY: "auto",
        overflowX: "hidden",
        wordBreak: "break-word",
        height: "300px",

    },
    action: {
        padding: "16px 8px 8px 0",

    },
    chatTextField: {
        width: "100%",
        border: "1px solid #767676",
        borderRadius: 4,
        padding: "5px",
        

    },
    messageColor: {
        color: "Black",
        border: "1px solid #E47029",
        padding: "5px 7px 7px",
        backgroundColor:"#FF914D"

    },

    messageUrl: {
        backgroundColor: "red",
    },
}));
