import { Badge, Fab, Grid, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ChatIcon from '@material-ui/icons/Forum';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import 'firebase/firestore';
import html2canvas from 'html2canvas';

import {
	changeQuality,
	muteMic,
	setEndCallStatus
} from '../../../shared/utilities/FirebaseConnectionCalls';

import useStyles from '../../../../styles/VideoContainerStyle/VideoContainer';
import useStore from "../../../shared/store";
import CustomAlert from '../../../shared/components/CustomAlert';
import videoStore from "./VideoStatus";
import VideoQualityButton from "./VideoQualityButton";
import { publishCameraState, publishShareState } from "./messageShare";

const StyledBadge = withStyles({
	root: {
		"& span": {
			backgroundColor: "#e47129",
			width: "11px",
			height: "11px",
			borderRadius: "50%"
		}
	}
})(Badge);

export default function VideoContainerBottomBar({loading, smallScreen, userInfo, callInfo, setAlert, onFullScreenClick, setChatDrawerOpen, notification, chatButton }) {
	const [anchorEle, setAnchorEle] = useState(null)
	const [mute, setMute] = useState(false);
	const [camOff, setCamOff] = useState(false);
	const [screenShareOff, setScreenShareOff] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

	// video quality adjustment states
	const {setVideoRatio} = videoStore();
	const {SIMPLE_PACKAGE, SOLO_PACKAGE, CLINIC_PACKAGE, currentPackage} = useStore();
	const [scaleRatio, setScaleRatio] = useState(0);
	const [videoMenuOpen, setVideoMenuOpen] = useState(false);
	const [videoAnchorEl, setVideoAnchorEl] = useState()
	const [videoResTag, setVideoResTag] = useState("")
	const [videoResLimiter, setVideoResLimiter] = useState({
		FHD_Menu_Button_Disable:false,
		HD_Menu_Button_Disable:false,
		SD_Menu_Button_Disable:false,
		LD_Menu_Button_Disable:false,
	})

	const classes = useStyles();
	
	const setMuteMic = () => {
	  if (!loading) {
	    muteMic(userInfo.userId, userInfo.doctor ? callInfo.patient.id : userInfo.userId, !mute)
	    .then(() => setMute(!mute))
	    .catch(() => setAlert('Error sending request to peer.', 'error'));
	  }
	};

	const handleCamClick = () => {
		if (!loading) {
			setCamOff((state) => {
				publishCameraState({camOffState: !state});
				return !state;
			})
		}
	}

	const handleScreenShare = () => {
		if (!loading) {
			setScreenShareOff((state) => {
				publishShareState({screenShareOffState: !state})
				return !state
			})
		}
	}

	const handleScreenShotClick = () => {
		html2canvas(document.getElementById("remoteStream")).then((canvas) => {
			const anchor = document.createElement("a");
			anchor.href = canvas.toDataURL("image/png;base64");
			anchor.download = `Screenshot from ${(new Date()).toISOString()}.png`;
			anchor.click();
			setOpenAlert(true);
		});
	}

	const endCall = () => {
	  setEndCallStatus(userInfo.doctor ? callInfo.patient.id : userInfo.userId)
	  // setCallStatus(userInfo.doctor ? callInfo.patient.id : userInfo.userId, 'end');
	}

	const handleMoreClose = () => {
	  setAnchorEle(null)
	}

	const handleMoreClick = (event) => {
	  console.log("more click")
	  setAnchorEle(event.currentTarget);
	};

	useEffect(() => {
		setVideoQuality();
	},[scaleRatio])

	const setVideoQuality = () => {
		if(!loading){
			changeQuality(userInfo.userId, userInfo.doctor ? callInfo.patient.id : userInfo.userId, scaleRatio)
				.then(() => setVideoRatio(scaleRatio));
			//console.log("scale ratio: " + scaleRatio);
		}
		switch (scaleRatio){
			case 0:
				setVideoResTag("Default");
				break;
			case 1:
				setVideoResTag("FHD");
				break;
			case 2:
				setVideoResTag("HD");
				break;
			case 3:
				setVideoResTag("SD");
				break;
			case 4:
				setVideoResTag("LD");
				break;
			default:
				setVideoResTag("Default");
				break;
		}
	}
	const closeMenu = () => {
		setVideoMenuOpen(false);
	}
	const recordVideoMenuPosition = (event) => {
		VideoResLimiter();
		setVideoAnchorEl(event.currentTarget);
		setVideoMenuOpen(true);
	}

	{/* TODO: When different packages are created the video quality needs to be variable*/}
	const VideoResLimiter = () => {
		//change this function for package limitation
		//set true to disable the options
		switch (currentPackage){
			case SIMPLE_PACKAGE:
				setVideoResLimiter({
					FHD_Menu_Button_Disable: true,
					HD_Menu_Button_Disable: true,
					SD_Menu_Button_Disable: false,
					LD_Menu_Button_Disable: false,
				})

				break;
			case SOLO_PACKAGE:
				setVideoResLimiter({
					FHD_Menu_Button_Disable: true,
					HD_Menu_Button_Disable: false,
					SD_Menu_Button_Disable: false,
					LD_Menu_Button_Disable: false,
				})
				break;
			case CLINIC_PACKAGE:
				setVideoResLimiter({
					FHD_Menu_Button_Disable: false,
					HD_Menu_Button_Disable: false,
					SD_Menu_Button_Disable: false,
					LD_Menu_Button_Disable: false,
				})
				break;
		}
	}

	const iconSize = smallScreen ? "small" : "medium";
	const isVideoCall = callInfo.type === "video";
	
	return (
		<Grid container xs={12} alignItems="center" className={classes.bottomBar} wrap="nowrap">
			{isVideoCall && smallScreen &&
					<Grid item xs={2} sm container justifyContent="flex-start" className={classes.footer}>
						<Fab className={classes.moreButton} size={iconSize} onClick={handleMoreClick}>
							<MoreHorizIcon fontSize={iconSize}/>
						</Fab>
						<Menu
							anchorEl={anchorEle}
							keepMounted
							open={Boolean(anchorEle)}
							onClose={handleMoreClose}
							anchorOrigin={{vertical: 'top', horizontal: 'right'}}
							transformOrigin={{vertical: 'top', horizontal: 'right'}}
						>
							<MenuItem>Full Screen</MenuItem>
							<MenuItem>Share Screen</MenuItem>
							<MenuItem>
								<VideoQualityButton
									recordVideoMenuPosition={recordVideoMenuPosition}
									videoAnchorEl={videoAnchorEl}
									videoResTag={videoResTag}
									scaleRatio={scaleRatio}
									videoMenuOpen={videoMenuOpen}
									closeMenu={closeMenu}
									videoResLimiter={videoResLimiter}
									setScaleRatio={setScaleRatio}
								/>
							</MenuItem>
						</Menu>
					</Grid>
			}

			{isVideoCall && !smallScreen &&
				<Grid item xs={4} sm container justifyContent="flex-start" className={classes.footer}>
					<span className={classes.endButtonLeft}>
						<Fab onClick={handleScreenShare} className={classes.button} size={iconSize} title="Share screen">
							{screenShareOff ? <ScreenShareIcon fontSize={iconSize}/> : <VideocamIcon fontSize={iconSize}/>}
						</Fab>
						<Fab onClick={onFullScreenClick} className={classes.button} size={iconSize} title="Full screen">
							<FullscreenIcon fontSize={iconSize}/>
						</Fab>
						<Fab onClick={handleScreenShotClick} className={classes.button} size={iconSize} title="Take a screenshot">
							{<WallpaperIcon fontSize={iconSize}/>}
						</Fab>
					</span>
				</Grid>
			}

			{!isVideoCall && <Grid item xs={4} sm container justifyContent="flex-start" className={classes.footer}></Grid>}

			<Grid container alignItems="center" justifyContent="center" item xs={8} sm className={classes.footer}>
				<span>
					<Fab onClick={setMuteMic} className={classes.button} size={iconSize} title={mute ? "Unmute" : "Mute"}>
						{mute ? <MicOffIcon fontSize={iconSize}/> : <MicIcon fontSize={iconSize}/>}
					</Fab>
					<Fab onClick={endCall} className={classes.button} style={{backgroundColor: "#e47129"}} size={iconSize} title="End Call">
						<CallEndIcon fontSize={iconSize}/>
					</Fab>
					{isVideoCall &&
						<Fab onClick={handleCamClick} className={classes.button} size={iconSize} title={camOff ? "Turn video on" : "Turn video off"}>
							{camOff ? <VideocamOffIcon fontSize={iconSize}/> : <VideocamIcon fontSize={iconSize}/>}
						</Fab>
					}
				</span>
			</Grid>

			<Grid container alignItems="center" justifyContent="flex-end" item xs={2} sm className={classes.endButtonRight} wrap='nowrap' title="Video quality">
				{isVideoCall && !smallScreen &&
					<VideoQualityButton
						recordVideoMenuPosition={recordVideoMenuPosition}
						videoAnchorEl={videoAnchorEl}
						videoResTag={videoResTag}
						scaleRatio={scaleRatio}
						videoMenuOpen={videoMenuOpen}
						closeMenu={closeMenu}
						videoResLimiter={videoResLimiter}
						setScaleRatio={setScaleRatio}
					/>
				}
				<Fab 
					ref={chatButton}
					onClick={() => setChatDrawerOpen(true)}
					className={classes.button}
					size={iconSize}
					title="Chat"
				>
					<StyledBadge variant="dot" invisible={!notification}>
						<ChatIcon fontSize={iconSize}/>
					</StyledBadge> 
				</Fab>
		  </Grid>
      <CustomAlert description={"Your screenshot has been saved to your Downloads folder."} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
		</Grid>
	)
}
