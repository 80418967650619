import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Paper, CircularProgress, Box, Divider, IconButton, Button, Menu, MenuItem, Badge, useMediaQuery } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Forum';
import MicIcon from '@material-ui/icons/Mic';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VideocamIcon from '@material-ui/icons/Videocam';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useStyles from '../../../styles/DashboardStyle';
import useStore from '../../shared/store';
import CustomAlert from '../../shared/components/CustomAlert';
import QueueRemoveDialog from './QueueRemoveDialog';
import { OutlinedOrangeButton } from '../../../styles/CustomButtons'
import { createBrowserHistory } from "history";
import { ReactComponent as WelcomeImage } from '../../shared/public/img/welcome.svg';
import StatusUpdate from '../../shared/components/StatusUpdate';
import validator from 'validator';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import json2mq from 'json2mq';

const PatientQueue = ({ setChats, setExpanded, patient, setPatientPosition, patientJoinCall, allUserChats, setStartCall, setCallReady, setCallInfo, selectedProvider }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElSession, setAnchorElSession] = useState(null);
    const [loading, setLoading] = useState(false);
    const [queue, setQueue] = useState(null);
    const [filteredQueue, setFilteredQueue] = useState(null)
    const [more, setMore] = useState(false);
    const [moreSession, setMoreSession] = useState(false);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [currentTime, setCurrentTime] = useState(moment());
    const [patientToRemove, setPatientToRemove] = useState(null);
    const [patientToCreateSession, setPatientToCreateSession] = useState(null);
    const [showNewEndStatus, setShowNewEndStatus] = useState({status: false, header: '', message: ''});

    //Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');

    const { user, whiteLabel, envVars } = useStore();
    const pingInterval = envVars.appPing;
    const smallScreenView = useMediaQuery(json2mq({ maxWidth: 600 }))
    const superSmallView = useMediaQuery(json2mq({ maxWidth: 368 }))
    const history = createBrowserHistory({ forceRefresh: true })
    let refeshWaitTime = useRef(null);

    useEffect(() => {
        refeshWaitTime.current = setInterval(() => {
            setCurrentTime(moment());
        }, 10000);

        return () => {
            clearInterval(refeshWaitTime.current);
        }
    }, []);

    // Listen to queue. To save reads and writes we only want to listen while on the dashbaord
    useEffect(() => {
        let unsubscribe;
        if(whiteLabel || patient) {
            const org = whiteLabel || patient.org;
            unsubscribe = firebase.firestore()
                .collection('patient-queue')
                .where("org", "==", org)
                .onSnapshot((snapshot) => {
                    let tempQueue = []
                    let time;
                    let lastUpdated;
                    let removed = true;
                    snapshot.forEach((doc) => {
                        if (patient && (doc.id === patient.id)) removed = false;
                        time = moment(doc.data().time.toDate());
                        lastUpdated = moment(doc.data().lastUpdated.toDate());
                        tempQueue.push({...doc.data(), time: time, lastUpdated: lastUpdated});
                    })

                    if (patient && removed) removePatient();

                    tempQueue.sort((t1, t2) => {
                        const t1Diff = moment().diff(t1.time, 'seconds');
                        const t2Diff = moment().diff(t2.time, 'seconds');
                        return t2Diff - t1Diff;
                    });
                    setQueue(tempQueue);
                }, (error) => {
                    console.log(error)
                    setLoading(false);
                    handleError('error', 'Unable to connect to patient queue. Please try again.');
                });
        }
        if (unsubscribe) return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whiteLabel, patient]);

    // If patient, listen to peer-connections. If there is a connection, leave the queue and enter the appointment session.
    useEffect(() => {
        if (patient) {
            const unsubscribe = firebase.firestore()
            .collection('peer-connections')
            .onSnapshot((snapshot) => {
                let meeting = false;
                let type = null;
                let callStatus = null;
                snapshot.forEach((doc) => {
                    if (patient.id === doc.id) {
                        type = doc.data().callType;
                        callStatus = doc.data().type;
                        meeting = true;
                       }
                })
                if (meeting && (callStatus !== 'end') && !patientJoinCall) {
                    setCallInfo({ doctor: `Dr. ${patient.doctor.charAt(0).toUpperCase()}${patient.doctor.slice(1)}`, type: type})
                    setCallReady(true)
                }
                if (meeting && (callStatus !== 'end') && patientJoinCall) {
                    firebase.firestore()
                    .collection('patient-queue')
                    .doc(patient.id)
                    .update({
                        inCall: true
                    }).then(() => {
                        setCallInfo({ doctor: `Dr. ${patient.doctor.charAt(0).toUpperCase()}${patient.doctor.slice(1)}`, type: type})
                        setStartCall(true);
                        return () => unsubscribe();
                    }).catch((error) => {
                        handleError('error', 'Unable to enter call. Please ask your provider to try again.');
                    })
                }
                if (meeting && (callStatus === 'end')) {
                    setChats({});
                    setShowNewEndStatus({
                        status: true, 
                        header: 'Your session has ended', 
                        message: 'Please re-enter the virtual walk in room if you have more questions'
                    });
                    localStorage.removeItem('session');
                }
            })
            if (unsubscribe) return () => unsubscribe();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient, patientJoinCall]);

    // If doctor, search for any active calls and re-join the call. Ended calls should have no documents. 
    useEffect(() => {

        const getCurrentCalls = async () => {
            const calls = await firebase.firestore()
            .collection('peer-connections')
            .get()

            calls.forEach((call) => {
                if ((call.data().users.includes(user._id)) && (call.data().type !== 'end')) {
                    setCallInfo(JSON.parse(call.data().callInfo))
                    setStartCall(true);
                }
            })
        };
        if (user && user.roles && user.roles[0] === 'doctor') getCurrentCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Rules for how the queue is viewed by each user type
    useEffect(() => {
        if (queue?.length > 0) {
            if (user.roles) {
                // User queue
                switch(user.roles[0]) {
                    case 'doctor':
                        const doctorFilteredQueue = queue.filter(patient => patient.doctor === user.lastname);
                        setFilteredQueue(doctorFilteredQueue.filter((patient) => (moment().diff(patient.lastUpdated, 'minutes') <= (pingInterval / 60000)) && !patient.inCall));
                        break;
                    default:
                        const filteredQueueByDoctor = queue.filter((patient) => {
                            return patient.doctorId === selectedProvider?._id;
                        });

                        setFilteredQueue(filteredQueueByDoctor.filter((patient) => (moment().diff(patient.lastUpdated, 'minutes') <= (pingInterval / 60000)) && !patient.inCall));
                }
            } else {
                // Patient queue
                setFilteredQueue(queue.filter((patient) => (moment().diff(patient.lastUpdated, 'minutes') <= (pingInterval / 60000)) && !patient.inCall));
            }
        } else {
            setFilteredQueue([]);
        }
    }, [queue, currentTime, pingInterval, user.roles, user.lastname, selectedProvider]);

    const removePatient = () => {
        setShowNewEndStatus({
            status: true, 
            header: `You were removed from Dr. ${patient.doctor.charAt(0).toUpperCase()}${patient.doctor.slice(1)}'s Virtual Room`, 
            message: 'Please re-enter the virtual walk in room if you have more questions'
        });
        localStorage.removeItem('session');
        // history.push('/');
    };

    const checkNotification = (chat) => {
        if (typeof chat !== 'undefined') {
            if (chat.chatWindow.viewing) {
                return true;
            } else {
                if (chat.messages.length) {
                    const latestMessage = chat.messages[chat.messages.length -1];
                    if (moment(latestMessage.time.toDate()).isAfter(chat.chatWindow.time.toDate()) && validator.isUUID(latestMessage.sender, 4)) {
                        return false;
                    }
                }
            } 
        } 
        return true;
    }

    const checkPermissionsBeforeCall = async (patientId) => {
        const doc = await firebase.firestore()
        .collection('patient-queue')
        .doc(patientId) 
        .get()

        return doc.data().permissions;
    };

    const handleError = (type, message) => {
        setType(type);
        setMessage(message);
        setOpenAlert(true);
    };

    const handleMore = (event) => {
        setAnchorEl(event.currentTarget);
        setMore(true);
    };

    const handleClose = () => {
        setMore(false);
        setAnchorEl(null);
    };

    const handleRemoveDialogOpen = () => {
        setRemoveDialog(true);
        setMore(false);
        setAnchorEl(null);
    };

    const handleMoreSession = (event) => {
        setAnchorElSession(event.currentTarget);
        setMoreSession(true);
    }

    const handleCloseSession = () => {
        setMoreSession(false);
        setAnchorElSession(null);
    };

    const handleVideoCall = () => {
        checkPermissionsBeforeCall(filteredQueue[patientToCreateSession].id)
        .then((permissions) => {
            if (permissions.video) {
                setCallInfo({ patient: filteredQueue[patientToCreateSession], type: 'video' });
                setStartCall(true); 
            } else {
                handleError('error', 'Please ask patient to enable their camera before the call can start.');
            }
        })
    };

    const handleAudioCall = () => {
        checkPermissionsBeforeCall(filteredQueue[patientToCreateSession].id)
        .then((permissions) => {
            if (permissions.audio) {
                setCallInfo({ patient: filteredQueue[patientToCreateSession], type: 'audio' });
                setStartCall(true);
            } else {
                handleError('error', 'Please ask patient to enable their microphone before the call can start.');
            }
        })
    }

    const renderDropdownMenu = () => {
        return (
        <Menu
            classes={{ list: classes.menu }}
            anchorEl={anchorElSession}
            open={moreSession}
            onClose={handleCloseSession}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem className={classes.menuItem} onClick={handleVideoCall}><VideocamIcon style={{ color: "#CB4D00" }}/>&nbsp;&nbsp;Video Call</MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleAudioCall}><MicIcon style={{ color: "#CB4D00" }}/>&nbsp;&nbsp;Voice Call</MenuItem>
        </Menu>
        )
    };


    const renderStartSessionButton = (index) => {
        return (
            
                index === 0 ?
                <>
                    <Button onClick={(e) => { handleMoreSession(e); setPatientToCreateSession(index) }} className={classes.queueSessionBtn} variant='contained' endIcon={<ArrowDropDownIcon />}>
                        Start Session
                    </Button>
                    { renderDropdownMenu() }
                </>
                    :
                <>
                    <OutlinedOrangeButton onClick={(e) => { handleMoreSession(e); setPatientToCreateSession(index) }} className={classes.queueSessionBtnSecondary} variant='contained' endIcon={<ArrowDropDownIcon />}>
                        Start Session
                    </OutlinedOrangeButton>
                    { renderDropdownMenu() }
                </>
        )
    }

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') ?
                    <Typography style={{color:"#CB4D00"}} variant='h3'>Patient Queue</Typography>
                    :
                    <Typography variant='h3'>Clinic Queue</Typography>
                }
            </Grid>
            <Grid item xs={12}>
                { loading ?
                    <Grid container component={Paper} className={classes.apt} justifyContent='center'>
                        <CircularProgress />
                    </Grid>
                    : filteredQueue && filteredQueue.length ?
                        <Grid container component={Paper} className={classes.columnPaper}>
                            {filteredQueue.map((apt, i) => (
                                <Grid item xs={12} key={i}>
                                    <Grid container className={classes.queue}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems='center'>
                                                {/*patient index*/}
                                                <Grid item lg={1} md={1} sm={1} xs={2}>
                                                    <Typography className={classes.queuePatientName} align='center'>{i + 1}</Typography>
                                                </Grid>

                                                {/*patient account and waiting time*/}
                                                <Grid item lg={6} md={3}  sm={4} xs={6}>
                                                    <Box display='flex' alignItems='center'>
                                                        {/*<AccountIcon className={classes.patientIcon} fontSize='large' />*/}
                                                        <Box>
                                                            <Typography 
                                                            className={
                                                                currentTime.diff(apt.time, 'minutes') < 30 ? 
                                                                (currentTime.diff(apt.time, 'minutes') < 15 ?
                                                                classes.waitingTimeTextEarly 
                                                                :
                                                                classes.waitingTimeTextWarning
                                                                )   
                                                                : 
                                                                classes.waitingTimeTextLate
                                                            }
                                                            >
                                                                {`Waiting for ${currentTime.diff(apt.time, 'minutes')} Min`}
                                                            </Typography>
                                                            {
                                                                (user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin'))
                                                                ?
                                                                <Typography className={classes.queuePatientName}>{apt.name}</Typography>
                                                                :
                                                                (apt.id !== patient.id) ?
                                                                    <Typography className={classes.queuePatientName}>Patient</Typography>
                                                                :
                                                                <>
                                                                { setPatientPosition(i + 1) }
                                                                <Typography className={classes.queuePatientName}>{apt.name}</Typography>
                                                                </>
                                                                
                                                            }
                                                            {!superSmallView && smallScreenView && user.roles && user.roles[0] === 'doctor'
                                                                && 
                                                                renderStartSessionButton(i)
                                                            }
                                                         </Box>
                                                    </Box>
                                                </Grid>

                                                {/*start session button*/}
                                                {!smallScreenView &&
                                                <Grid item lg={3} md={4}  sm={5}>
                                                    {user.roles && (user.roles[0] === 'doctor') 
                                                    && 
                                                        renderStartSessionButton(i)
                                                    }
                                                </Grid>
                                                }

                                                {/*chat icon */}
                                                <Grid item lg={1} md={2} sm={1} xs={2}>
                                                    {user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') &&
                                                        <IconButton onClick={() => { setChats((prev) => ({ ...prev, [apt.id]: apt })); setExpanded((prev) => ({ ...prev, [apt.id]: true })); }}>
                                                            <Badge color="secondary" variant="dot" invisible={checkNotification(allUserChats[apt.id])} style={{zIndex: '0'}}>   
                                                                <ChatIcon />
                                                            </Badge> 
                                                        </IconButton>
                                                    }
                                                </Grid>

                                                {/*more icon*/}
                                                <Grid item lg={1} md={2} sm={1} xs={2}>
                                                    {user.roles && (user.roles[0] === 'oa' || user.roles[0] === 'doctor' || user.roles[0] === 'healthOrgAdmin' || user.roles[0] === 'admin') &&
                                                        <IconButton onClick={(e) => { handleMore(e); setPatientToRemove(i) }} name='more'>
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    }
                                                </Grid>

                                                {/*for super small screen view show the button at the bottom*/}
                                                {
                                                    superSmallView && user.roles && user.roles[0] === 'doctor' &&
                                                    <>
                                                        <Grid item xs={2}></Grid>
                                                        <Grid item xs={10}>
                                                            {
                                                               renderStartSessionButton(i)
                                                            }           
                                                        </Grid>
                                                    </>
                                                   
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {filteredQueue.length > 1 && <Divider />}
                                </Grid>
                            ))}

                            <Menu
                                classes={{ list: classes.menu }}
                                anchorEl={anchorEl}
                                open={more}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                            <MenuItem style={{backgroundColor:"#FFB98E",color:"black"}} className={classes.menuItem} onClick={handleRemoveDialogOpen}>Remove</MenuItem>
                            </Menu>
                            <QueueRemoveDialog 
                                patient={filteredQueue[patientToRemove]} 
                                open={removeDialog} handleClose={() => setRemoveDialog(false)} 
                                setOpenAlert={setOpenAlert}
                                setMessage={setMessage}
                                setType={setType}
                                setFilteredQueue= {setFilteredQueue}
                                filteredQueue = {filteredQueue}
                            />
                        </Grid>
                        :
                        <Grid container component={Paper} className={classes.apt}>
                            <Grid item xs={12} container justifyContent='center' className={classes.imageText}>
                                <WelcomeImage className={classes.image} />
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant='h5' align='center'>No Patients in Queue</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.aptCell}>
                                <Typography align='center'>There is no patient waiting currently</Typography>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid >
        <CustomAlert description={message} type={type} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
        {
            showNewEndStatus.status && 
            <StatusUpdate 
                header={showNewEndStatus.header} 
                message={showNewEndStatus.message}
                closeAction={() => {history.push("/")}}
                 />
        }
        </>
    );
};

export default PatientQueue;
