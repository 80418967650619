import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../../../styles/DashboardStyle";
import firebase from "firebase/app";
import "firebase/firestore";

const QueueRemoveDialog = ({
    open,
    handleClose,
    patient,
    setOpenAlert,
    setMessage,
    setType,
    setFilteredQueue,
    filteredQueue,
}) => {
    const classes = useStyles();

    const removePatient = () => {
        firebase
            .firestore()
            .collection("patient-queue")
            .doc(patient.id)
            .delete()
            .then(() => {
                setMessage("Patient successfully removed.");
                setType("success");
                setOpenAlert(true);
            })
            .then(() => {
                if (filteredQueue.length === 1) {
                    setFilteredQueue({
                        filteredQueue: filteredQueue.filter(
                            (item) => item.id !== patient.id
                        ),
                    });
                }
            })
            .catch((error) => {
                setMessage("Patient successfully removed.");
                setType("error");
                setOpenAlert(true);
            });
    };

    return (
        <Dialog maxWidth="sm" open={open}>
            <DialogTitle
                classes={{ root: classes.inviteDialogTitle }}
                disableTypography
            >
                <Typography style={{ color: "#444444" }} variant="h4">
                    <strong>Remove Patient</strong>
                </Typography>
                <CloseIcon onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ color: "black" }}>
                    Are you sure you want to remove Patient from queue? You will
                    not be able to revert this.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <div className={classes.inviteDialogAction}>
                    <Button
                        onClick={handleClose}
                        className={classes.inviteDialogActionBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            removePatient();
                            handleClose();
                        }}
                        className={classes.inviteDialogActionBtn}
                    >
                        Yes
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default QueueRemoveDialog;
