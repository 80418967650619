import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  chat: {
    flex: 1,
  },
  
  chatContainer: {
    height: "33rem",
    width: '270px',
    padding: '0 3%',
    overflowY: 'auto',
    overflowX: 'hidden',
    wordBreak: 'break-word',

    [theme.breakpoints.down(630)]: {
      height: '32rem',
    }
  },

  chatInputContainer: {
    height: '5rem',
    width: '270px',
    marginLeft: '15px',
    position: 'relative',

    [theme.breakpoints.down(700)]: {
      height: '5rem',
    }
  },

  action: {
    // padding: '16px 8px 8px 0',
    // marginTop: '42px'
  },

  chatTextField: {
    border: '1px solid #D3D3D3',
    borderRadius: 4,
    width: '90%',
    marginRight: '15px'
  },

  messageSender: {
    marginTop: '15px',
    paddingLeft: '15px',
    marginRight: '100px'
  },

  messageRecipient: {
    marginTop: '15px',
    textAlign: 'right',
    paddingRight: '15px',
    marginLeft: '100px'
  },

  message: {
    fontSize: 16,
    color: 'black',
    border: '1px solid #D3D3D3',
    borderRadius: 10,
    padding: '0 5px 0 5px',
    display: 'inline-block',
  },

  messageColor: {
    backgroundColor: ' #FF914D'
  }
}));
