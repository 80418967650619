import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";
import apiConfigs from "../shared/utilities/apiConfigs";
import {api} from "../shared/utilities/api";
import ReportFooter from "../../frame/GetReportFooter";
import PatientReportHeader from "../../frame/PatientReportHeader";
import GetPatientReportForm from "../GetPatientReports/GetPatientReportForm";
import UploadPatientReportForm from "./UploadPatientReportForm";

const useStyles = makeStyles((theme) => ({
    page: {
        backgroundColor: "#F5F6FA",
        minHeight: "100vh",
        height: "auto",
        display: "flex",
        flexDirection: "column",
    },
    pageContent: {
        flex: 1,
    },
    cardWrapper: {
        padding: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            maxWidth: "85%",
        },
    },
}));

const UploadPatientReports = () => {
    const { orgName, orgId, userId, hashedId, sessionId } = useParams();
    console.log("orgId",orgId)
    const [logo, setLogo] = useState(
        "https://porton-shared.s3.ca-central-1.amazonaws.com/images/Porton_New_Logo.png"
    );

    const [orgInfo, setOrgInfo] = useState({
        address: "",
        contactNum: "",
        email: "",
    });

    const getOrgDetails = async (orgId) => {
        try {

            const result = await api(
                apiConfigs.baseOption('get', `orgs/${orgId}/getReport`)
            );      console.log("result", result);

            if (result.status === 200) {
                const orgLogo = _.get(
                    result,
                    "data.logo",
                    "https://porton-shared.s3.ca-central-1.amazonaws.com/images/Porton_New_Logo.png"
                );
                if (orgLogo) {
                    setLogo(orgLogo);
                }

                const address = _.get(result, "data.address", "");
                const contactNum = _.get(result, "data.contactNum", "");
                const email = _.get(result, "data.email", "");
                setOrgInfo({ address, contactNum, email });
            }
        } catch (error) {
            console.error("Error retrieving files:", error);
            // Handle error, show error message, or fallback behavior
        }
    };

    const fetchOrgDetails = async (orgId) => {
        try {
            await getOrgDetails(orgId);
        } catch (error) {
            console.error('Error fetching organization details:', error);
        }
    };

    useEffect(() => {
        if (orgId) {
            fetchOrgDetails(orgId);
        }
    }, [orgId]);
    const classes = useStyles();
    return (
        <div className={classes.page}>
            <div className={classes.pageContent}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={8} className={classes.cardWrapper}>
                        <PatientReportHeader
                            logo={logo}
                            address={orgInfo.address}
                            contactNum={orgInfo.contactNum}
                            email={orgInfo.email}
                        />
                        <Card elevation={2}>
                            <UploadPatientReportForm
                                orgName={orgName}
                                orgId={orgId}
                                hashedId={hashedId}
                                sessionId={sessionId}
                                logo={logo}
                                userId={userId}
                            />
                        </Card>
                    </Grid>
                    <ReportFooter
                        address={orgInfo.address}
                        contactNum={orgInfo.contactNum}
                        email={orgInfo.email}
                    />
                </Grid>
            </div>
        </div>
    );
};

export default UploadPatientReports;
