import React, { useState } from 'react';
import { Button, CircularProgress, Typography, Card, CardContent, TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {api} from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";
import useStore from "../shared/store";

const FileUploader = ({orgName,hashedId,sessionId,hin, orgId,userId}) => {
    const [file, setFile] = useState(null); // Stores the uploaded file
    const [fileName, setFileName] = useState(''); // Stores the file name
    const [loading, setLoading] = useState(false); // To handle the loading state
    // Handle file selection and validation (JPEG, PNG)
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            alert('Please upload a JPEG or PNG file.');
        }
    };

    // Handle file deletion
    const handleDelete = () => {
        setFile(null);
        setFileName('');
    };

    // Submit and upload the file
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            alert('Please upload a file before submitting.');
            return;
        }

        const confirmation = window.confirm('Are you sure you want to share this file?');
        if (!confirmation) return;

        setLoading(true);

            const base64File = await toBase64(file);

            console.log("det",orgName,
                hashedId,
                sessionId,)
            // Prepare file data
            const fileData = {
                orgName,
                orgId,
                userId,
                hashedId,
                sessionId,
                hin,
                file: {
                    name: fileName,
                    document: base64File, // Base64 encoded file
                },
            };
            console.log("det",                orgName,
                hashedId,
                sessionId,)

            setLoading(true); // Set loading initially

            try {
                const resp = await api(apiConfigs.baseOption('POST', 'file/uploadFileToS3WithUrl', { data: fileData }));

                // Check response status
                if (resp?.status === 200) {
                    console.log("File uploaded successfully:", resp.data);
                    setLoading(false); //
                } else {
                    console.error("File upload failed with status:", resp?.status);
                    setLoading(false); //
                }
            } catch (error) {
                console.error("Error during file upload:", error);
                setLoading(false); //
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }

        };

    // Helper function to convert file to base64
    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    return (
        <Card sx={{ maxWidth: 500, margin: 'auto', mt: 5, padding: 2, boxShadow: 3 }}>
            <CardContent>

                <Typography variant="h5" gutterBottom align="center" style={{fontSize:"xx-large"}}>
                    Upload Your Report
                </Typography>
                {/* Message from Doctor */}
                <Typography variant="body1" gutterBottom align="center">
                    Dear Test Patient,<br />
                    Dr. Porton Health has requested you to upload your report. Please use the following link to upload your file.
                </Typography>

                {/* Upload Section */}
                {!file ? (
                    <div style={{ marginBottom: '20px' }}>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                            style={{background:"#f08733", color: 'white'}}
                        >
                            Upload Your Report
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={handleFileChange}
                                hidden
                            />
                        </Button>
                    </div>
                ) : (
                    <div>
                        <TextField
                            label="Attached File"
                            variant="outlined"
                            value={fileName}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleDelete}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                ),
                            }}
                            disabled
                        />
                    </div>
                )}

                {/* Submit Button */}
                {file && (
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            style={{background:"#f08733", color: 'white'}}
                            onClick={handleSubmit}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? 'Uploading...' : 'Save & Submit'}
                        </Button>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default FileUploader;