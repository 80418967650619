import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        paddingTop: "1rem",
        backgroundColor: "#F5F6FA",
    },

    inputStyle: {
        border: "1px solid black",
        borderRadius: "4px",
    },
    root2: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        background: "#EAEAEA",
        // marginLeft: "0",
        fontSize: "14px",
    },
    iconButton: {
        padding: 10,
        background: "#EAEAEA",
    },
    clearButton: {
        paddingRight: "1rem",
        fontSize: "1.1rem",
        opacity: 0.5,
        background: theme.palette.primary.orange,
        "&:hover": {
            opacity: 0.8,
            background: "none",
        },
        "&:active": {},
    },
    TodaysAppointments: {
        padding: 32,

    },
    userName: {
        color: theme.palette.primary.orange,
    },
    paper: {
        padding: 32,
    },
    paper2: {
        padding: 22,
    },
    shareTitle: {
        fontSize: 20,
        fontWeight: 600,
        color: "#343434",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "6px",
        marginRight: "6px",
    },
    shareTitleStyle: {
        color: "black",
        fontSize: "21px",
        marginTop: "6px",
        fontWeight: 600,
        marginLeft: "3px",
        marginRight: "6px",
        marginBottom: "-2px",
    },
    invitedDialogStyle: {
        color: "red",
        fontSize: "15px",
        marginBottom: "5px",
        marginLeft: "1px",
    },
    dialogContent: {
        padding: '12px 24px'
    },
    shareLink: {
        // http://localhost:8087?org=firsth&doctor=Provider
        display: 'flex',
        padding: '5px 0 5px 20px ',
        fontSize: '1.2em',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#f3f6f4',

        '& .input-wrapper': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
        },

        '& .link-icon': {
            borderRight: '1px solid #bcbcbc',
            paddingRight: '10px',
            marginRight: '7px',
            fill: '#699be0',
        },

        '& .url': {
            padding: '8px 15px',
        }
    },
    shareLinkField: {
        padding: '3px 10px 4px',
        pointerEvents: "none",
    },
    inviteDialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inviteDialogAction: {
        display: 'flex',
        justifyContent: "space-between",
        padding: "0 20px 5px",
        width: "100%",
    },
    inviteDialogActionBtn: {
        color: "#e47029",
        fontWeight: 600,
    },
    copyBtn: {
        paddingRight: 22,
        paddingLeft: 22,
        color: 'white',
        background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
        
        "&:hover": {
            color: "white",
            background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
        },
    },
    inviteBtn: {
        color: "white",
        marginLeft: "7px",
        background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
        border: "#e47029",
        "@media (max-width:1515px)": {
            height: "40px",
        },
    },
    columnPaper: {
        maxHeight: 400,
        overflowX: "hidden",
    },
    menu: {
        padding: 0,
    },
    menuItem: {
        minWidth: 190,
        height: 48,
        color: "black",
        "&:hover": {
            backgroundColor: "#FFB98E",
            color: "#343434",
        },
    },
    videoBtn: {
        background:
            "radial-gradient(73.95% 73.95% at 14.58%  11.46%, #EE8F37 0%, #E47029 100%)",
        paddingTop: 16,
        paddingBottom: 16,
        minWidth: 190,
    },
    videoBtnDisabled: {
        backgroundColor: "#EAEAEA",
        color: "#BDBDBD",
        paddingTop: 16,
        paddingBottom: 16,
        minWidth: 190,
    },
    apt: {
        padding: 18,
        
    },
    aptCell: {
        marginTop: 22,
    },
    iconCell: {
        display: "flex",
        
        "& svg": {
            paddingRight: "5px",
        }
    },
    cellWrapper: {
        "& strong": {
            display: "none",
        },

        "@media (max-width: 768px)": {
            display: "inline-flex",
            width: "45%",
            border: "none",
            padding: "10px 15px",
    
            "& > div": {
                display: "flex",
                flexDirection: "column",
            },

            "& strong": {
                display: "block",
            },
        },
   
        "@media (max-width: 425px)": {
            width: "100%",
        }
    },
    rowWrapper: {
        "@media (max-width: 768px)": {
            borderBottom: "1px solid #d2cbcb",
        },
    },
    waitingTimeTextEarly: {
        color: "#118761",
        fontSize: 12,
        fontWeight: 500,
        textTransform: "uppercase",
    },
    waitingTimeTextWarning: {
        color: "#FFA500",
        fontSize: 12,
        fontWeight: 500,
        textTransform: "uppercase",
    },
    waitingTimeTextLate: {
        color: "#D14444",
        fontSize: 12,
        fontWeight: 500,
        textTransform: "uppercase",
    },
    queue: {
        paddingTop: 32,
        paddingBottom: 32,
    },
    queuePatientName: {
        fontSize: 20,
        fontWeight: 500,
        color: "black",
    },
    queueSessionBtn: {
        background:
            "radial-gradient(73.95% 73.95% at 14.58%  11.46%, #EE8F37 0%, #E47029 100%)",
        minWidth: 190,
        minHeight: 40,
        fontSize: 16,
        fontWeight: 500,
        "@media (max-width:500px)": {
            minWidth: 80,
            padding: "0 5px 0 5px",
        },
    },
    queueSessionBtnSecondary: {
        background: "white",
        color: theme.palette.primary.orange,
        border: `2.5px solid ${theme.palette.primary.orange}`,
        "&:hover": {
            background: "white",
        },
        minWidth: 190,
        minHeight: 40,
        fontSize: 16,
        fontWeight: 500,
        "@media (max-width:500px)": {
            minWidth: 80,
            padding: "0 5px 0 5px",
        },
    },
    chatGrid: {
        position: "fixed",
        bottom: 0,
        right: 24,
    },
    chatHeader: {
        backgroundColor: "#FF914D",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    chatClosed: {
        bottom: 0,
        maxWidth: 240,
    },
    chatOpen: {
        bottom: 0,
        width: 336,
    },
    dashboardContainer: {
        backgroundColor: "#F5F6FA",
    },
    image: {
        width: "auto",
        height: "250px",
    },
    imageText: {
        marginBottom: "30px",
    },
    disableBackdrop: {
        backgroundColor: "transparent",
    },
    chatBody: {
        padding: 0,
    },
    providerMenuButton: {
        background: "#FFFFFF",
        color: "blue",
        borderRadius: 8,
        minWidth: 216,
        minHeight: 40,
        fontSize: 16,
        boxShadow: "0px 2px 4px 0px #0823303D",
        fontWeight: 500,
        fontStyle: "medium",
        align: "left",
        letterSpacing: "0.5px",
        lineHeight: "100%",
    },
    providerMenuItem: {
        borderRadius: "4px",
        minWidth: 216,
        color: "blue",
        textAlign: "left",
        "&:hover": {
            color: "#343434",
        },
    },
}));
