import {
  Button,
  Grid,
  IconButton,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import useStyles from '../../../styles/DashboardStyle';
import useStore from '../../shared/store';
import { api } from '../../shared/utilities/api';
import apiConfigs from '../../shared/utilities/apiConfigs';

const ProviderMenu = ({ setSelectedProvider, selectedProvider }) => {
  const classes = useStyles();
  const [menuOptions, setMenuOptions] = useState([]);
  const { user } = useStore();

  useEffect(() => {
    getOrgProviders()
      .then((res) => {
        if (res.length < 0) {
          console.log(
            'No Providers with Audio or Video call appointments Enabled'
          );
        }
        setMenuOptions(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  const getOrgProviders = async () => {
    const fetchOrgProviders = await api(
      apiConfigs.baseOption('get', 'appointments/getOrgProviders', {
        params: { org_id: user.default_org, limit: -1 },
      })
    );
    if (fetchOrgProviders && fetchOrgProviders.status === 200) {
      const filteredProviders = fetchOrgProviders.data.data.data.filter(
        (doctor) => {
          // null/undefined check is needed here because it is possible for doctors to not have appointment settings setup
          if (doctor.modes) {
            return doctor.modes.includes('001') || doctor.modes.includes('002');
          } else {
            return false;
          }
        }
      );
      return filteredProviders;
    } else {
      return fetchOrgProviders.status;
    }
  };

  const handleMenuItemClick = (event, index) => {
    if (index) {
      user.contact_name = index.contact_name;
      setSelectedProvider(index);
    } else {
      delete user.contact_name;
      setSelectedProvider('');
    }
  };

  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ width: 350, color: 'black' }}
        placement="bottom-start"
      />
    );
  };

  return (
    <Grid
      container
      component={Paper}
      className={classes.paper2}
      style={{ alignItems: 'center' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', flex: '1' }}>
        <IconButton
          style={{
            cursor: 'none',
            borderRadius: '0',
            borderTopLeftRadius: '25px',
            borderBottomLeftRadius: '25px',
            pointerEvents: 'none',
          }}
          disabledtype="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon style={{ color: 'black' }} />
        </IconButton>
        <Autocomplete
          PopperComponent={PopperMy}
          style={{ display: 'flex', flex: '1' }}
          options={menuOptions}
          getOptionLabel={(option) => option.contact_name}
          sx={{ width: '100%' }}
          onChange={(event, value) => handleMenuItemClick(event, value)}
          renderInput={(params) => (
            <TextField {...params} className={classes.input} />
          )}
        />

        <Button
          className={classes.inviteBtn}
          style={{ cursor: 'none', pointerEvents: 'none' }}
        >
          {selectedProvider ? selectedProvider.contact_name : 'Select Provider'}
        </Button>
      </div>
    </Grid>
  );
};

export default ProviderMenu;
