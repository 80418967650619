/*
 * @Author: Michael Zaghi
 * @Date: 2021-04-15 01:06:14
 * @LastEditors: Michael Zaghi
 * @LastEditTime: 2021-04-15 01:06:14
 * @Description: 
 * A class that has methods which can be used to establish a video or audio connection between two peers.
 * Uses the WebRTC API built into the browser.
 */

export class WebRTC {

  constructor(servers) {
    //TODO: init with previously defined tracks saved from pre-call

    //TODO: This should be our TURN server
    console.log(">>>>> ", servers)
    this.servers = servers;
  }

  /**
  * Prompts the user for permission to their video and audio
  * @return {object|null} The users local video and/or audio stream. Returns null if permision issues or no device
  */
  async initLocalStream(type, constraints) {
    
    let config;
    if (type === 'video') {
      config = { video: {constraints}, audio: true };
    } else {
      config = { video: false, audio: true }
    }

    try {
      const stream = navigator.mediaDevices.getUserMedia(config)
      return stream;
    } catch(error) {
      throw new Error('Unable to initialize video stream. Please check your permisions.')
    }
  }

  /**
  * Sets up our peer connection and gets our connection information from the ICE server
  * @param {object} localStream
  * @return {object|null} Returns the connection object
  */
  async initPeerConnection(localStream) {
    try {
      const conn = new RTCPeerConnection({ iceServers: this.servers });
      /*
      TODO: We want to use addTrack() since addStream is depricated.
      This needs to be updated so we can use the settings (tracks) we selected in pre-call

      addTrack(track, stream) where track represents a single media track
      */
      conn.addStream(localStream);
      return conn;
    }catch(error) {
      console.log(error)
      throw new Error('Unable to create connection.')
    }
  }

 /**
  * Creates an offer to a peer through firebase. The offer object contains the SDP string and we
  * use this to set the description of our connection which is used by the peer.
  * @param {object} conn Conection object
  * @param {string} toPatientId The patient id
  * @param {string} userId The user sending the offer (doctor)
  * @param {string} callType The call type can be either video or audio
  * @param {object} callInfo All patient info that gets passed back into the call if the doctor reconnects
  * @callback doOffer once the offer is created it is sent to firebase
  */
  async createOffer(conn, toPatientId, userId, callType, callInfo, doOffer) {
    try {
      const offer = await conn.createOffer();
      await conn.setLocalDescription(offer);
      
      doOffer(toPatientId, userId, callType, callInfo, offer)

    } catch(error) {
      throw new Error('Unable to send connection information to peer');
    }
  }

   /**
  * Creates an answer to an offer. Then the remote description (offer from doctor) 
  * and the local description (patient answer) is set by the patient.
  * @param {string} userId Should be the patient id
  * @param {object} conn Conection object
  * @param {object} message The firebase document
  * @callback doAnswer once the answer is created it is sent to firebase
  */
  async sendAnswer(userId, conn, message, doAnswer) {
    try {
      const offer = JSON.parse(message.offer);
      await conn.setRemoteDescription(offer);

      const answer = await conn.createAnswer();
      await conn.setLocalDescription(answer);

      doAnswer(userId, answer);
    } catch(error) {
      console.log(error)
      throw new Error('Unable to send connection information to peer');
    }
  }

  /**
  * Listens for any connection events. This could be new ICE candidates (new network path)
  * or the remove peer changing the track (device) on their stream. Basically, and updated network information.
  * @param {string} userId User/patient id updating the candidate
  * @param {string} patientId Patient id (document id)
  * @param {object} conn Connection object
  * @param {object} remoteVideoRef The video element for the remote screen
  * @callback doCandidate Updates the best ICE candidate
  */
  async listenForConnectionsEvents(userId, patientId, conn, remoteVideoRef, doCandidate) {
    conn.onicecandidate = function(e) {
      if (e.candidate) {
        try {
          doCandidate(userId, patientId, e.candidate);
        } catch(error) {
          throw new Error('Unable to update new connection.')
        }
      }
    }

    conn.ontrack = (e) => {
      // If the new track is not the current track
      if (remoteVideoRef.srcObject !== e.streams[0]) {
      remoteVideoRef.srcObject = e.streams[0];
      }
    }
  }

  /**
  * On upadating or getting updated from the peer with a new candidate, update the connection.
  * @param {object} conn Conection object
  * @param {object} message The firebase document
  */
  async addCandidate(conn, message) {
    try {
      if (message.candidate) {
        const candidate = JSON.parse(message.candidate);
        await conn.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch(error) {
      console.log(error)
      throw new Error('Unable to update new connection.')
    }
  }

  /**
  * Doctor starts the call when they get updated with answer from the patient (peer)
  * @param {object} conn Conection object
  * @param {object} message The firebase document
  */
  async startCall(conn, message) {
    try {
      const answer = JSON.parse(message.answer);
      await conn.setRemoteDescription(answer);
    } catch(error) {
      console.log(error);
      throw new Error('Unable to start call.')
    }
  }

  /**
    closing a connection 
  */
  async close(conn) {
    conn.getSenders().forEach((sender) => {
      conn.removeTrack(sender)
    })
    conn.close();
  }
}

